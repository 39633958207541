export const lowerCaseSpecial = (haystack, needle) => {
  return haystack.replaceAll(
    new RegExp(needle, 'gi'),
    `<span style="text-transform: lowercase">${needle}</span>`
  );
};

export const lowerCase6e = (haystack) => {
  return lowerCaseSpecial(haystack, '6e');
};
