import {
  addMonths,
  startOfDay,
  endOfDay,
  isValid,
  isBefore,
  isAfter,
  format,
  formatISO,
} from 'date-fns';
import { de } from 'date-fns/locale';
import punycode from 'punycode';
import ent from 'ent';
import { gaPush } from 'Utils/ga_push';
import { isSpecialTracked, getTrackingDataFromElement } from 'Utils/trackingAttributes';
import DOMPurify from 'dompurify';
import parseFromHtml from 'html-react-parser';

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stripEmptyValues = (payload: Object) => {
  const copy = {};
  Object.keys(payload).forEach(key => {
    // if value is not an array but an actual object, recursively strip empty values for child object
    if (typeof payload[key] === 'object' && payload[key].map === undefined) {
      const newVal = stripEmptyValues(payload[key]);
      if (Object.keys(newVal).length > 0) {
        copy[key] = newVal;
      }
    }
    // if value is a string, only use it if it contains anything other than whitespace
    else if (typeof payload[key] === 'string') {
      if (payload[key].trim().length > 0) {
        copy[key] = payload[key];
      }
    }
    // any other values are fine as long as they're not undefined/null
    else if (payload[key] !== undefined && payload[key] !== null) {
      copy[key] = payload[key];
    }
  });
  return copy;
};

export function flatten(arr) {
  return arr.reduce((explored, toExplore) => {
    return explored.concat(Array.isArray(toExplore) ? flatten(toExplore) : toExplore);
  }, []);
}

export function parseDate(dateString: string, format: string = 'D.M.YYYY'): ?boolean {
  let date;

  switch (format) {
    case 'YYYYMMDD':
      if (!/^\d{8}$/.test(dateString)) {
        return null;
      }

      date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8));
      break;
    default:
      if (!/^\d{1,2}.\d{1,2}.\d{4}$/.test(dateString)) {
        return null;
      }

      const parts = dateString.split('.');
      date = new Date(parts[2], parts[1] - 1, parts[0]);
  }

  return dateValid(date) ? date : null;
}

export function dateValid(date: Date): boolean {
  return isValid(date);
}

export function dateIsBefore(date: Date, dateToCompare: Date): boolean {
  return isBefore(date, dateToCompare);
}

export function dateIsAfter(date: Date, dateToCompare: Date): boolean {
  return isAfter(date, dateToCompare);
}

export function dateStartOfDay(date: Date): boolean {
  return startOfDay(date);
}

export function dateEndOfDay(date: Date): boolean {
  return endOfDay(date);
}

const toDate = (date: Date | string) => (typeof date === 'string' ? new Date(date) : date);

export function formatDate(date: Date | string, dateFormat: string = 'dd.MM.yyyy'): string {
  return format(toDate(date), dateFormat, { locale: de });
}

export function formatTime(time: Date | string, timeFormat: string = 'HH:mm:ss') {
  return format(toDate(time), timeFormat, { locale: de });
}

/**
 * Format the date according to the ISO 8601 standard
 * @param date
 * @return {string}
 */
export function formatDateToISO(date: Date | string): string {
  return formatISO(toDate(date));
}

export function secondsToMinutes(seconds: Number): string {
  return Math.floor(seconds / 60) + 'm ' + Math.floor(seconds % 60) + 's ';
}

export function fromApiDate(dateString) {
  if (dateString) {
    return new Date(dateString);
  }

  return new Date();
}

export function toApiDate(date) {
  if (date instanceof Date) {
    return date.toISOString();
  }
  return null;
}

export function addMonthsToDate(date: Date, months: number = 0): Date {
  return addMonths(date, months);
}

export function formatPrice(price) {
  if (window.Intl) {
    return `${new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(price)} €`;
  }

  price = Number(price).toFixed(2);
  price = price.replace('.', ',');
  return `${price} €`;
}

export function formatLicensePlate(plate) {
  return plate && plate.length > 0 ? plate.replace(/[^a-z0-9äöüß]*/gi, '') : '';
}

export function sanitizeTags(text) {
  return stripTags(ent.decode(text || ''));
}

export function loadAsync(url, returnElement, isModule, withCrossOrigin) {
  const tag = url.indexOf('.css') !== -1 ? 'link' : 'script';

  let selector;
  switch (tag) {
    case 'link':
      selector = `link[href="${url}"]`;
      break;
    default:
      selector = `script[src="${url}"]`;
  }

  if (document.querySelectorAll(selector).length) {
    return new Promise((resolve, _) => {
      resolve(url);
    });
  }

  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    let parent = 'body';
    let attr = 'src';

    if (!returnElement) {
      element.onload = () => resolve(url);
      element.onerror = () => reject(url);
    }

    // Need to set different attributes depending on tag type
    switch (tag) {
      case 'link':
        element.type = 'text/css';
        element.rel = 'stylesheet';
        attr = 'href';
        parent = 'head';
        break;
      default:
        element.async = true;
        if (isModule) {
          element.type = 'module';
        }
        if (withCrossOrigin) {
          element.crossOrigin = true;
        }
    }

    // Inject into document to kick off loading
    element[attr] = url;
    document[parent].appendChild(element);

    if (returnElement) {
      resolve({ element });
    }
  });
}

export function mapOrder(array, order, key) {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    }
    return -1;
  });

  return array;
}

export function slugify(text) {
  // Use hash map for special characters
  const specialChars = {
    'à': 'a',
    'ä': 'a',
    'á': 'a',
    'â': 'a',
    'æ': 'a',
    'å': 'a',
    'ë': 'e',
    'è': 'e',
    'é': 'e',
    'ê': 'e',
    'î': 'i',
    'ï': 'i',
    'ì': 'i',
    'í': 'i',
    'ò': 'o',
    'ó': 'o',
    'ö': 'o',
    'ô': 'o',
    'ø': 'o',
    'ù': 'o',
    'ú': 'u',
    'ü': 'u',
    'û': 'u',
    'ñ': 'n',
    'ç': 'c',
    'ß': 's',
    'ÿ': 'y',
    'œ': 'o',
    'ŕ': 'r',
    'ś': 's',
    'ń': 'n',
    'ṕ': 'p',
    'ẃ': 'w',
    'ǵ': 'g',
    'ǹ': 'n',
    'ḿ': 'm',
    'ǘ': 'u',
    'ẍ': 'x',
    'ź': 'z',
    'ḧ': 'h',
    '·': '-',
    '/': '-',
    _: '-',
    ',': '-',
    ':': '-',
    ';': '-',
  };

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/./g, target => specialChars[target] || target) // Replace special characters using the hash map
    .replace(/&/g, '-und-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function hasCookie(name: string): boolean {
  return document.cookie.split(';').filter((item: string) => {
    return item.includes(`${name}=`);
  }).length;
}

export function getCookie(name: string): string {
  const cookie: [string] = document.cookie.split(';').filter((item: string) => {
    return item.includes(`${name}=`);
  });

  if (cookie && cookie.length === 1) {
    return decodeURIComponent(cookie[0].split(`${name}=`).pop());
  }

  return '';
}

export function setCookie(name: string, value: string, path: string = '/', days: number = 0) {
  let expires: string = '';

  if (days > 0) {
    const date: Date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = 'expires=' + date.toUTCString();
  }

  document.cookie = name + '=' + encodeURIComponent(value) + ';path=' + path + '; ' + expires;
}

export function deleteCookie(name: string, domain: string): boolean {
  domain =
    domain ||
    window.location.host
      .split('.')
      .slice(1)
      .join('.');
  document.cookie = name + `=; Path=/; Domain=.${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function isExternalLink(link: string = '') {
  return (
    link &&
    (link.indexOf('http') === 0 || link.indexOf('https') === 0 || link.indexOf('//') === 0) &&
    link.indexOf(window.location.host) === -1
  );
}

export function checkRelativeLink(link) {
  if (!isExternalLink(link)) {
    return link.replace(/http:\/\/|https:\/\//gi, '').replace(window.location.host, '');
  }
  return link;
}

export function getRTECharCount(rteText, plainText = false) {
  // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/draft-js-counter-plugin/src/CharCounter/index.js#L12
  const decodeUnicode = str => punycode.ucs2.decode(str);
  const regex = /(?:\r\n|\r|\n)/g;
  const regex2 = /\n(( ){2})/g; /* searches spaces which were placed for li-Elements */
  const cleanString = !plainText ? rteText.replace(regex, '') : rteText.replace(regex2, '\n');
  return decodeUnicode(cleanString.trim()).length;
}

export function stripTags(input, allowed) {
  // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
  allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  return input.replace(commentsAndPhpTags, '').replace(tags, function($0, $1) {
    return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
  });
}

/**
 * Matches content within <sup> even for dirty tags.
 *
 * - <sup>FN42</sup>
 * - <  sup > O sole mioooo     <     /   sup  >
 *
 * @type {RegExp}
 */
export const footnoteRegExp = /<\s*sup[^>]*>(.*?)<\s*\/\s*sup>/gm;

/**
 * Remove Footnotes from given string.
 *
 * @param {string} input
 * @return {string}
 */
export function stripFootnotes(input: string) {
  return input.replace(footnoteRegExp, '');
}

export const findFootnotes = str => {
  const re = [];

  if (!str) {
    return re;
  }

  const content = str.toUpperCase(); // ignore cases for footnotes
  const regex = /<SUP>\s*((FN|G)\d+)\s*<\/SUP>/gm;
  let m;
  const addedIds = [];

  while ((m = regex.exec(content)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    const [, id, group] = m;

    // only add every id once
    if (addedIds.includes(id)) {
      continue;
    }

    addedIds.push(id);
    re.push({
      id: id,
      relation: group === 'FN' ? 'single' : 'multiple',
    });
  }

  return re;
};

export const priceTypes = [
  { value: 'FIXED', label: 'fest' },
  { value: 'FROM', label: 'ab' },
  { value: 'TIL', label: 'bis zu' },
  { value: 'FREE', label: 'kostenlos ' },
  { value: 'INCLUDED', label: 'inklusive ' },
  { value: 'NONE', label: 'kein' },
];

export const priceMap = new Map([
  ['FROM', 'ab '],
  ['FIXED', ''],
  ['TIL', 'bis zu '],
  ['FREE', 'kostenlos '],
  ['INCLUDED', 'inklusive '],
]);

export const enableTracking = () => {
  document.addEventListener('click', evt => {
    if (!evt.target) return;

    const { target } = evt;

    if (isSpecialTracked(target)) {
      return gaPush(getTrackingDataFromElement(target));
    }

    const ctaTrigger = target.closest('.cta');

    if (ctaTrigger) {
      let label = ctaTrigger.innerText.toLowerCase();

      if (label.indexOf('\n') > -1) {
        const tmp = label.split('\n');

        if (tmp.length === 2 && tmp[0] === tmp[1]) {
          label = tmp[0];
        }
      }

      const trackingLabel =
        ctaTrigger.getAttribute('data-tracking-label') ||
        ctaTrigger.getAttribute('data-track-label');
      window.cta = ctaTrigger;

      const trackAction = ctaTrigger.getAttribute('data-track-action');

      gaPush({
        event: 'gaEvent',
        category: 'CTA',
        action: trackAction || 'button click',
        label: trackingLabel || label,
      });
    }
  });
};

export const addPrefix = (value: string, prefix: string = '') => {
  return value.indexOf(prefix) >= 0 ? value : `${prefix}${value}`;
};

// we cut out the domain if it's same-site (useful for generic links such as /werkstatttermin)
// 10369.mazda.local/probefahrt => /probefahrt
export const sanitizeUrl = (url: ?string) => {
  if (!url || url[0] === '/') {
    return url;
  }

  const { host } = window.location;
  const http = `http://${host}`;
  const https = `https://${host}`;

  if (url.indexOf(http) === 0) {
    return url.slice(http.length);
  }

  if (url.indexOf(https) === 0) {
    return url.slice(https.length);
  }

  if (url.indexOf(host) === 0) {
    return url.slice(host.length);
  }

  // https starts with http too...
  if (url.indexOf('http') === 0) {
    return url;
  }

  return `//${url}`;
};

export const validButtonTypes = ['primary', 'secondary', 'tertiary'];

export const validTargets = ['_self', '_top', '_parent', '_blank'];

export const isValidTarget = (target: string) => validTargets.includes(target);

export const isNullOrUndefined = value => value === null || value === undefined;

export function romanize(num) {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
    ],
    roman = '',
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
}

export const getHrefWithoutQueries = href => {
  if (typeof href !== 'string') return href;

  const i = href.indexOf('?');
  if (i < 0) return href;

  return href.substring(0, i);
};

export const purifyHtmlToReact = (str: string): string => {
  const sanitizedData = DOMPurify.sanitize(str);
  return parseFromHtml(sanitizedData);
};
