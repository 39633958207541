import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'Components/Grid';
import styles from './styles.scss';

export default class OpeningHourBox extends React.Component {
  static propTypes = {
    openingHours: PropTypes.array,
  };

  renderEntry(data, numberOfEntries) {
    let m;
    m = numberOfEntries === 1 ? 12 : m;
    m = numberOfEntries === 2 ? 6 : m;
    m = numberOfEntries === 3 ? 6 : m;

    let xl;
    xl = numberOfEntries === 1 ? 12 : xl;
    xl = numberOfEntries === 2 ? 6 : xl;
    xl = numberOfEntries === 3 ? 4 : xl;

    return (
      <Col cols={12} m={m} xl={xl} className={styles.entry} key={data.headline}>
        <div className={styles.headline}>{data.headline}</div>
        {data.entries.map((entry) => {
          return (
            <div key={entry.type + entry.dayOfWeek + entry.from} className={styles.body}>
              <div className={styles.days}>{entry.dayOfWeekDe}</div>
              <div className={styles.times}>{`${entry.from} - ${entry.to}`}</div>
            </div>
          );
        })}
      </Col>
    );
  }

  render() {
    const { openingHours } = this.props;
    const showRoom = openingHours.find(function (element) {
      return element.headline === 'ÖFFNUNGSZEITEN SHOWROOM';
    });

    return (
      <Fragment>
        <Row>
          {openingHours.map((data) => {
            return this.renderEntry(data, openingHours.length);
          })}
        </Row>
        {showRoom && (
          <Row>
            <Col>
              <p className={styles.legal}>
                Beratung und Verkauf nur innerhalb der gesetzlichen Öffnungszeiten
              </p>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}
