import React, { Fragment } from 'react';
import Icon from 'Components/Icon';
import styles from './styles.scss';
import {
  emailTracking,
  telephoneTracking,
  addressTracking,
  whatsappTracking,
} from 'Utils/trackingAttributes';

export default class DealerContact extends React.Component {
  render() {
    const { branch } = this.props;
    const { address, whatsappWidget } = branch;
    const phone = branch.phone ? branch.phone.replace(/[^\d+]/g, '') : null;
    const addressData = address || {
      city: '',
      houseNumber: '',
      postalCode: '',
      street: '',
    };
    const destination = `${branch.name},${addressData.street},${addressData.postalCode}`
      .replace(/&/g, '%26')
      .replace(/\s+/g, '%20');
    const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

    return (
      <div className={styles.address__item}>
        <div className={styles.contact}>
          <div className={styles.contact__header}>Adresse</div>
          <div className={styles.contact__location}>
            <Icon name="marker" className={styles.icon} inline={false} />

            <a
              target="_blank"
              rel="noopener noreferrer"
              data-e2e={'address'}
              href={url}
              {...addressTracking('Dealer Contact Dropdown')}
            >
              <div className={styles.contact__address}>
                {addressData.street} {addressData.houseNumber}
                <br />
                {addressData.postalCode} {addressData.city}
              </div>

              <div className={styles.contact__schedule} data-e2e={'route'}>
                Route planen
              </div>
            </a>
          </div>

          {phone && (
            <Fragment>
              <div className={styles.contact__header}>Kontakt</div>
              <div className={styles.contact__phone}>
                <Icon name="phone-small" className={styles.icon} inline={false} />

                <a
                  href={`tel:${phone}`}
                  {...telephoneTracking('Dealer Contact Dropdown')}
                  data-e2e={'phone'}
                >
                  {branch.phone}
                </a>
              </div>
            </Fragment>
          )}

          {branch.email && (
            <div className={styles.contact__email}>
              <Icon name="mail-small" className={styles.icon} inline={false} />

              <a
                href={`mailto:${branch.email}`}
                {...emailTracking('Dealer Contact Dropdown')}
                data-e2e={'email'}
              >
                {branch.email}
              </a>
            </div>
          )}

          {whatsappWidget && whatsappWidget.whatsappUrl && (
            <div className={styles.contact__whatsapp} style={{ display: 'flex' }}>
              <img
                alt=""
                className={styles.icon}
                src={`${process.env.ASSET_ENV_HOST}/assets/img/icon_whatsapp_green.svg`}
              />
              <a
                href={whatsappWidget.whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                {...whatsappTracking('Dealer Contact Dropdown')}
                data-e2e={'whatsapp'}
              >
                WhatsApp Chat starten
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
