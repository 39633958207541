// @flow
import React from 'react';
import styles from './styles.scss';
import cn from 'classnames';

type Props = {
  headlines: Array<string>,
  rows: Array<Array<string>>,
  styles?: { [string]: string },
  narrow?: boolean,
};

export default class Table extends React.Component<Props> {
  static defaultProps = {
    offerList: [],
    narrow: false,
  };

  useStyle = (key) => {
    const { styles: customStyle } = this.props;

    return (customStyle && customStyle[key]) || styles[key];
  };

  render() {
    const { rows, headlines, fixedColumGrey, narrow } = this.props;

    return (
      <div className={this.useStyle('scroll')}>
        <table
          className={cn(this.useStyle('tableWrapper'), {
            [this.useStyle('fixedColumGrey')]: !!fixedColumGrey,
            [this.useStyle('narrow')]: !!narrow,
          })}
        >
          <tbody>
            <tr className={this.useStyle('row')}>
              {headlines.map((headline: string, i: number) => {
                return (
                  <td
                    key={`th${i}`}
                    className={this.useStyle('td')}
                    dangerouslySetInnerHTML={{ __html: headline }}
                  />
                );
              })}
            </tr>
            {rows.map((row, i: number) => {
              return (
                <tr key={`tr${i}`} className={this.useStyle('row')}>
                  {row.map((entry, j: number) => {
                    if (i === 0) {
                      return (
                        <td key={`td${i}${j}`} className={this.useStyle('td')}>
                          {entry}
                        </td>
                      );
                    }
                    return (
                      <td key={`td${i}${j}`} className={this.useStyle('td')}>
                        {entry}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
