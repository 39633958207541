import { MODAL_TYPES } from '../../containers/ContactForm/contactFormConstants';
import queryString from 'query-string';

export function getAdjustedModalType(config) {
  let { initialModalType, isServicePartner, isSalesPartner, isOsbUser, targetHref } = config;

  const targetModalType = targetHref && queryString.parse(targetHref);

  initialModalType =
    !!targetHref && !!targetModalType && !!targetModalType.modaltype
      ? targetModalType.modaltype
      : initialModalType;

  const SPOnly = isServicePartner && !isSalesPartner;
  const VPOnly = !isServicePartner && isSalesPartner;

  // OSB Full:
  switch (initialModalType) {
    case MODAL_TYPES.TEST_DRIVE.name:
    case MODAL_TYPES.REQUEST_FOR_PROPOSAL.name:
    case MODAL_TYPES.NEWSLETTER.name:
    case MODAL_TYPES.CAR_CONFIGURATOR.name:
    case MODAL_TYPES.REQUEST_FOR_CONSULTATION.name:
      if (SPOnly) return MODAL_TYPES.SERVICE_REQUEST.name;
      return initialModalType;

    // Tabelle ToDo: Zeile 1
    case MODAL_TYPES.SERVICE_APPOINTMENT.name:
    case MODAL_TYPES.HEADER_WERKSTATTTERMIN.name:
    case MODAL_TYPES.FLYOUT_WERKSTATTTERMIN.name:
    case MODAL_TYPES.SERVICE_OSR.name:
      if (isOsbUser) return MODAL_TYPES.SERVICE_CONTACT.name;
      if (!isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      return initialModalType;

    // Tabelle ToDo: Zeile 2
    case MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name:
      if (isOsbUser) return MODAL_TYPES.ONLINE_SERVICE_BOOKING.name;
      if (!isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      return initialModalType;

    // Tabelle ToDo: Zeile 4
    case MODAL_TYPES.REQUEST_FOR_CONSULTATION_FK.name:
      if (SPOnly && isOsbUser) return MODAL_TYPES.SERVICE_REQUEST.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      if (!isOsbUser) return initialModalType;
      return initialModalType;

    case MODAL_TYPES.REQUEST_FOR_CONSULTATION_WS.name:
      if (SPOnly && isOsbUser) return MODAL_TYPES.SERVICE_REQUEST.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      if (!isOsbUser) return MODAL_TYPES.REQUEST_FOR_CONSULTATION_OSBFB.name;
      return initialModalType;

    // Tabelle ToDo: Zeile 5, 11, 13
    case MODAL_TYPES.ONLINE_SERVICE_BOOKING.name:
      if (!isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name; // Tabelle ToDo: Zeile 6
      return initialModalType;

    // Tabelle ToDo: Zeile 7, 9, 10, 12
    case MODAL_TYPES.SERVICE_REQUEST.name:
      if (!isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      return initialModalType;

    // Tabelle ToDo: Zeile 8
    case MODAL_TYPES.SERVICE_CONTACT.name:
      if (!isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (VPOnly) return MODAL_TYPES.REQUEST_FOR_CONSULTATION.name;
      return initialModalType;

    case MODAL_TYPES.REQUEST_FOR_CONSULTATION_OSBFB.name:
      if (SPOnly && isOsbUser) return MODAL_TYPES.SERVICE_REQUEST.name;
      if (SPOnly && !isOsbUser) return MODAL_TYPES.SERVICE_BOOKING_FALLBACK.name;
      if (isOsbUser) return MODAL_TYPES.REQUEST_FOR_CONSULTATION_WS.name;
      return initialModalType;

    default:
      return initialModalType;
  }
}
