import styles from './styles.scss';

const bgColorMap = {
  black: styles.black,
  white: styles.white,
  grey: styles.grey,
};
const fgColorMap = {
  white: styles.fg_white,
};
const bgColors = [bgColorMap.white, bgColorMap.grey];

export const getBackground = (index, reverse) => {
  if (!reverse) return bgColors[index % 2];
  return bgColors[+!(index % 2)]; // switch 0 and 1
};
export const startsReversed = (background) => background === bgColorMap.grey;
export const getGivenBackground = (color) => bgColorMap[color || 'white'];
export const needsWhiteForeground = (color) => color !== bgColorMap.white;
export const getForeground = (color) => (needsWhiteForeground(color) ? fgColorMap.white : null);

export const hasDarkBackground = (color) => color === bgColorMap.black;
