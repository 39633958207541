import React, { Fragment } from 'react';
import styles from './styles.scss';

export const InfoTextForMatelsoCookies = (props) => {
  return (
    <Fragment>
      <h5>Matelso Call Tracking</h5>
      <br />
      <h6 className={styles.h7_small_margin}>Beschreibung des Dienstes</h6>
      Das ist ein Tracking-Dienst zum Zwecke der Konversionmessung und -optimierung. Weitere
      Informationen hierzu finden Sie in unserer Datenschutzerklärung.
      <br />
      <br />
      <h6 className={styles.h7_small_margin}>Verarbeitendes Unternehmen</h6>
      matelso GmbH, Europaallee 34, 67657 Kaiserslautern, Deutschland; Website:
      <a href="https://matelso.com/">https://matelso.com/</a>; Informationen zum Datenschutz:{' '}
      <a href="https://matelso.com/datenschutz/">https://matelso.com/datenschutz/</a>.
      <br />
      <ul>
        <li>
          <u>
            <strong>Kategorie</strong>
          </u>
          <ul>
            <li>Analyse-/Tracking-Cookies</li>
          </ul>
        </li>
        <li>
          <u>
            <strong>Zweck der Daten</strong>
          </u>
          <br />
          Diese Liste stellt die Zwecke der Erhebung und Verarbeitung der Daten dar.
          <ul>
            <li>Konversionsmessung/-optimierung</li>
            <li>Kampagnenoptimierung</li>
          </ul>
        </li>
        <li>
          <u>
            <strong>Erhobene Daten</strong>
          </u>
          <br />
          Diese Liste enthält alle (personenbezogenen) Daten, die durch oder bei der Verwendung
          dieses Dienstes erhoben werden.
          <ul>
            <li>Rufnummer</li>
            <li>Caller-ID</li>
            <li>Datum und Uhrzeit des Anrufs</li>
            <li>Dauer des Anrufs</li>
            <li>IP-Adresse</li>
            <li>Cookie-IDs</li>
          </ul>
        </li>
      </ul>
      <h6 className={styles.h7}>Verwendete Technologien</h6>
      Diese Liste stellt alle Technologien dar, die dieser Dienst zur Datenerhebung verwendet.
      Typische Technologien sind Cookies und Pixel, die im Browser platziert werden.
      <ul>
        <li>Cookies</li>
        <li>Skript</li>
      </ul>
      <h6 className={styles.h7}>Rechtsgrundlagen</h6>
      Im Folgenden sind die erforderlichen Rechtsgrundlagen der Datenverarbeitung aufgeführt.
      <ul>
        <li>Art. 6 Abs. 1 s. 1 lit. a DSGVO</li>
      </ul>
      <h6 className={styles.h7}>Standort der Verarbeitung</h6>
      Das ist der primäre Standort der Verarbeitung der erhobenen Daten. Wir informieren Sie, falls
      die Informationen in anderen Ländern verarbeitet werden.
      <ul>
        <li>Europäische Union</li>
      </ul>
      <h6 className={styles.h7}>Dauer der Datenspeicherung</h6>
      Die Aufbewahrungsfrist ist die Zeitspanne, in der die erhobenen Daten für die Zwecke der
      Verarbeitung gespeichert werden. Die Daten sind zu löschen, sobald sie für die angegebenen
      Verarbeitungszwecke nicht mehr benötigt werden.
      <ul>
        <li>
          Die Daten sind zu löschen, sobald sie für die angegebenen Verarbeitungszwecke nicht mehr
          benötigt werden.
        </li>
        <li>Maximale Dauer der Cookie-Speicherung: 2 Jahre</li>
      </ul>
      <h6 className={styles.h7}>Vertrieb in Drittländern</h6>
      Der Dienst selbst überträgt die erhobenen Daten nicht in ein anderes Land. Bitte beachten Sie,
      dass dieser Dienst die Daten an Google Analytics überträgt.
      <h6 className={styles.h7}>Datenempfänger</h6>
      Im Folgenden sind die Empfänger der erhobenen Daten aufgeführt.
      <ul>
        <li>matelso GmbH</li>
      </ul>
      <a href="https://matelso.com/datenschutz/">
        Klicken Sie hier, um die Datenschutzrichtlinie des Datenverarbeiters zu lesen.
      </a>
      <br />
      <ul>
        <li>Alphabet Inc., Google LLC, Google Ireland Limited</li>
      </ul>
      <br />
      <br />
      <br />
    </Fragment>
  );
};
