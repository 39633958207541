// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import FocusedImage from 'Components/FocusedImage';
import AssetModel from 'Stores/AssetModel';
import Button from 'Components/Button';
import styles from './styles.scss';
import onContactButtonClick from 'Utils/onContactButtonClick';
import { getTabByTarget } from 'Utils/getContactTab';
import { isExternalLink } from 'Utils/index';
import ConsumptionData from 'Components/ConsumptionData';
import { withUrlParams } from 'Utils/withUrlParams';
import { withRouter } from 'react-router-dom';
import { lowerCase6e } from 'Utils/special/lowerCaseSpecial';

type Props = {
  title?: string,
  description?: string,
  image: AssetModel,
  href?: string,
  scrollToTopOnOpen?: boolean,
  onClick?: () => void,
  onOpenContact: () => void,
  hasForm?: string,
};

class LinkSectionLink extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.state = {
      showConsumption: true,
    };
  }

  linkClick = (event) => {
    const {
      onOpenContact,
      link = {},
      scrollToTopOnOpen,
      href,
      scrollToDeeplinkId,
      location,
    } = this.props;

    const hrefIsCurrentLocation = href && href.includes(location.pathname);
    if (hrefIsCurrentLocation && href && href.includes('deeplinkid')) {
      setTimeout(() => {
        scrollToDeeplinkId();
      }, 100);
    }

    const { model, type, mazdaForm } = link;
    const tabIndex = getTabByTarget(type);

    if (scrollToTopOnOpen) {
      window.scrollTo(0, 0);
    }

    if (tabIndex > -1) {
      // actually impossible that it's undefined but sure is sure
      if (event) {
        event.preventDefault();
      }

      onContactButtonClick(!mazdaForm, () => onOpenContact(tabIndex, model))();
    }
  };

  renderButton = () => {
    const { href, onClick, title } = this.props;
    const hasSpecial = title.includes('6E') || title.includes('6e');

    return (
      <Button
        label={title}
        type="secondary"
        href={href || undefined}
        upperCase={!hasSpecial}
        onClick={(event) => {
          if (onClick) {
            onClick();
          }

          this.linkClick(event);
        }}
      />
    );
  };

  render() {
    const { description, image, href, link, onClick, deeplinkId, consumptionText, title } =
      this.props;
    const { showConsumption } = this.state;
    const isExternal = isExternalLink(href);

    return (
      <div className={styles.link} id={deeplinkId}>
        {image && (
          <div className={styles.image} onClick={onClick || undefined}>
            {consumptionText && (
              <ConsumptionData
                actionIcon={showConsumption ? 'close' : 'eco'}
                onClick={() =>
                  this.setState((old) => ({
                    showConsumption: !old.showConsumption,
                  }))
                }
                style={{
                  opactiy: +showConsumption,
                }}
                consumptionText={[consumptionText]}
                inside="tile"
              />
            )}
            {href && isExternal && (
              <a href={href} target="_blank" rel="noopener noreferrer">
                <FocusedImage image={image} useThumb="M" />
              </a>
            )}
            {href && !isExternal && (
              <Link to={href} onClick={this.linkClick}>
                <FocusedImage image={image} useThumb="M" />
              </Link>
            )}
            {link && link.mazdaForm && (
              <div onClick={this.linkClick} className={styles.imageContainer}>
                <FocusedImage image={image} useThumb="M" />
              </div>
            )}
            {!href && link && !link.mazdaForm && <FocusedImage image={image} useThumb="M" />}
          </div>
        )}
        <div className={styles.footer}>
          {href ? (
            <div>
              {description && <p dangerouslySetInnerHTML={{ __html: lowerCase6e(description) }} />}
              <div className={styles.button}>{this.renderButton()}</div>
            </div>
          ) : (
            <div>
              <div className={styles.sectionTitle}>
                <h4 dangerouslySetInnerHTML={{ __html: lowerCase6e(title) }} />
              </div>
              {description && <p dangerouslySetInnerHTML={{ __html: lowerCase6e(description) }} />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withUrlParams(LinkSectionLink));
