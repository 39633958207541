export function exchangeLabelIfNecessary(label, hrefOrModalType) {
  if (
    ['SERVICE_APPOINTMENT', 'SERVICE_OSR'].some(
      (target) => hrefOrModalType && hrefOrModalType.includes(target)
    )
  ) {
    return 'Termin vereinbaren';
  }

  if (
    ['SERVICE_BOOKING_FALLBACK', 'SERVICE_REQUEST'].some(
      (target) => hrefOrModalType && hrefOrModalType.includes(target)
    )
  ) {
    return 'Serviceberater kontaktieren';
  }

  if (
    ['ONLINE_SERVICE_BOOKING', 'SERVICE_CONTACT'].some(
      (target) => hrefOrModalType && hrefOrModalType.includes(target)
    )
  ) {
    return 'Termin buchen';
  }

  return label;
}

export function exchangeTrackingLabelIfNecessary(trackingLabel, modalType) {
  if (!modalType) return trackingLabel;

  if (['SERVICE_APPOINTMENT'].some((target) => modalType.includes(target))) {
    return 'servicerequest';
  }

  if (['ONLINE_SERVICE_BOOKING'].some((target) => modalType.includes(target))) {
    return 'online_service_booking';
  }

  if (
    ['SERVICE_BOOKING_FALLBACK', 'SERVICE_REQUEST', 'SERVICE_CONTACT'].some((target) =>
      modalType.includes(target)
    )
  ) {
    return 'servicecontact';
  }

  return trackingLabel;
}
