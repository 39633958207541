import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const icons = {
  'caret-down':
    'M24 6.5c0 .3-.1.5-.3.7l-11 11c-.4.4-1 .4-1.4 0l-11-11c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L12 16.1 22.3 5.8c.4-.4 1-.4 1.4 0 .2.2.3.5.3.7z',
  notification:
    'M12.5 21.7c-.1 0-.2 0-.3-.1-.2-.1-.4-.3-.4-.6v-4.3H5c-1.5 0-2.6-1.2-2.6-2.7V5c0-1.5 1.2-2.6 2.6-2.6h14c1.5 0 2.7 1.2 2.7 2.6v9c0 1.5-1.2 2.7-2.7 2.7h-.9l-5.2 4.8c-.1.1-.3.2-.4.2zM5 3.6c-.7 0-1.4.7-1.4 1.4v9c0 .7.6 1.3 1.4 1.3h7.5c.4 0 .7.3.7.7v3.5l4.3-4c.1-.1.3-.2.4-.2H19c.7 0 1.3-.6 1.3-1.3V5c0-.7-.6-1.4-1.3-1.4H5z',
  close:
    'M13.4 12L22 3.5c.4-.4.4-1 0-1.4s-1-.4-1.4 0L12 10.6 3.5 2c-.4-.3-1.1-.3-1.5 0s-.4 1 0 1.4l8.5 8.5L2 20.5c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l8.5-8.5 8.5 8.5c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L13.4 12z',
  'caret-left':
    'M17.5 24c-.3 0-.5-.1-.7-.3l-11-11c-.4-.4-.4-1 0-1.4l11-11c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.9 12l10.3 10.3c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3z',
  address:
    'M14.6.5c-2.1 0-3.7 1.7-3.7 3.7 0 .6 0 1 .4 1.6.8 1.4 3.3 5.7 3.3 5.7s2.4-4.2 3.3-5.6c.3-.5.4-1.1.4-1.7 0-2-1.6-3.7-3.7-3.7zm0 5.4c-.9 0-1.7-.7-1.7-1.7s.7-1.7 1.7-1.7c.9 0 1.7.7 1.7 1.7s-.8 1.7-1.7 1.7zm8.8 1.3L20 5.9c-.1.4-.3.7-.4 1-.1.2-.3.5-.4.8L22 8.8V21l-3.5-1.4V8.8c-.8 1.3-1.6 2.8-2 3.5v7.2l-9 1.8V9l2.9-.6c-.4-.6-.7-1.2-.9-1.6 0-.1-.1-.1-.1-.2l-2.8.5-5.2-2c-.3-.2-.7-.1-1 .1-.2.2-.4.5-.4.8v14.4c0 .4.3.8.6.9l5.5 2.1c.1 0 .2.1.3.1h.2l10.7-2.1 5.2 2c.1 0 .2.1.4.1s.4-.1.6-.2c.3-.2.4-.5.4-.8V8.1c.1-.4-.2-.7-.5-.9zM5.5 21L2 19.7V7.5l3.5 1.4V21z',
  marker:
    'M18.8 9.4c0 2.8-1.5 5-3 6.9C15 17.3 13 20 13 20l-.6.8-.6-.8s-2-2.6-2.8-3.7c-1.5-2-3-4.1-3-6.9C6 5.9 8.9 3 12.4 3c3.5 0 6.4 2.9 6.4 6.4zm-1.5 0c0-2.7-2.2-4.9-4.9-4.9S7.5 6.7 7.5 9.4c0 2.1 1 3.8 2.7 6 .5.7 1.6 2 2.2 2.9.7-.9 1.7-2.2 2.2-2.9 1.7-2.2 2.7-3.9 2.7-6zm-2.9-.6c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z',
  phone:
    'M23.6 17.5l-4.4-4.4-.1-.1c-.1 0-.1-.1-.2-.1h-.7l-.1.1c-.1 0-.1.1-.2.1l-1.9 2c-.4.4-.9.7-1.5.7s-1.1-.2-1.5-.6l-4-3.9c-1-1-1-2.4-.2-3.3l1.9-2s0-.1.1-.1c0-.1.1-.1.1-.2v-.2-.2-.2-.2c0-.1-.1-.2-.2-.2 0 0 0-.1-.1-.1L6.3.3S6.2.2 6.1.2C6 .1 6 .1 5.9.1h-.2c0-.1-.1-.1-.2-.1H5.2c-.1.1-.1.2-.2.2 0 0-.1 0-.2.1L2.6 2.6C-.8 6.2-.8 12 2.8 15.5l5.9 5.8c1.7 1.7 3.9 2.6 6.3 2.6h.1c2.4 0 4.7-1 6.4-2.8l2.2-2.3c.3-.3.3-.9-.1-1.3zM20 19.8c-1.3 1.4-3.1 2.1-5 2.1-1.9.1-3.7-.7-5-2l-5.9-5.8C1.4 11.4 1.4 6.8 4.1 4l1.5-1.6 3 3-1.2 1.3c-1.6 1.7-1.6 4.3.1 6l4 3.9c.8.8 1.8 1.2 2.9 1.2h.1c1.1 0 2.2-.5 2.9-1.3l1.2-1.2 3 3-1.6 1.5z',
  'phone-small':
    'M3.1125 16.5c-.3-.9 0-1.5.9-1.9.6-.3 1.5-.7 2.2-1 .5-.2 1-.5 1.4-.6.2-.1.4-.2.7-.2.5 0 .9.4 1 .5l.9.9c.5-.3 1.4-.9 2.2-1.7.8-.8 1.4-1.7 1.7-2.2l-.9-.9c-.7-.6-.6-1.2-.3-1.8.2-.4.4-.8.6-1.4.3-.7.7-1.6 1-2.2.1-.3.4-1 1.2-1 .2 0 .4.1.6.1 2 .6 4 2.1 4 4.2 0 2-1.6 5.7-4.6 8.6-3 3-6.6 4.6-8.6 4.6-1.9.1-3.5-2-4-4zm1.5-.4c.4 1.4 1.5 2.9 2.6 2.9 1.4 0 4.7-1.3 7.6-4.1 2.8-2.8 4.1-6.1 4.1-7.6 0-1.1-1.5-2.2-2.9-2.6-.3.6-.7 1.5-1 2.2-.2.5-.5 1-.6 1.3v.1l1.7 1.7-.2.5c0 .1-.8 1.6-2.3 3.1-1.5 1.5-3 2.2-3.1 2.3l-.5.2-1.7-1.7h-.1c-.3.2-.8.4-1.3.6-.9.4-1.7.8-2.3 1.1z',
  'social-facebook':
    'M18 3H6C4.3 3 3 4.3 3 6v12c0 1.7 1.3 3 3 3h6v-6.4h-2V12h2V9.7C12 7.8 13.2 6 16 6c1.1 0 2 .2 2 .2l-.1 2.5h-1.8c-1 0-1.2.6-1.2 1.4v2h3l-.2 2.6H15V21h3c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3z',
  'social-instagram':
    'M21 8.7h-5.5c-.9-.9-2.2-1.5-3.5-1.5s-2.6.5-3.5 1.5H3V5.6C3 4.2 4.2 3 5.6 3h12.8C19.8 3 21 4.2 21 5.6v3.1zm0 1.4v8.3c0 1.4-1.2 2.6-2.6 2.6H5.6C4.2 21 3 19.8 3 18.4v-8.3h4.5c-.3.6-.4 1.3-.4 1.9 0 2.7 2.2 4.9 4.9 4.9s4.9-2.2 4.9-4.9c0-.6-.1-1.3-.4-1.9H21zM8.7 12c0-1.9 1.5-3.3 3.4-3.3s3.4 1.5 3.4 3.3c0 1.9-1.5 3.4-3.4 3.4-2-.1-3.4-1.5-3.4-3.4z',
  'social-youtube':
    'M15.8 12L9.5 7.7v8.4l6.3-4.1zm6.3 0c0 8 0 8-10.1 8S1.9 20 1.9 12s0-8 10.1-8 10.1 0 10.1 8z',
  link: 'M8.5,16.5c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l7-7c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-7,7C9,16.4,8.8,16.5,8.5,16.5zM17.5,13h-0.9c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h0.9C20,11,22,9,22,6.5S20,2,17.5,2S13,4,13,6.5v0.9c0,0.6-0.4,1-1,1s-1-0.4-1-1V6.5C11,2.9,13.9,0,17.5,0S24,2.9,24,6.5S21.1,13,17.5,13zM6.5,24C2.9,24,0,21.1,0,17.5S2.9,11,6.5,11h0.9c0.6,0,1,0.4,1,1s-0.4,1-1,1H6.5C4,13,2,15,2,17.5S4,22,6.5,22s4.5-2,4.5-4.5v-0.9c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v0.9C13,21.1,10.1,24,6.5,24z',
  clock:
    'M6.4 1.8c.4-.3.5-1 .2-1.4-.4-.4-1-.5-1.4-.1L.4 4.1c-.4.3-.5 1-.2 1.4.2.2.5.4.8.4.2 0 .4-.1.6-.2l4.8-3.9zm17.2 2.3L18.8.3c-.4-.3-1.1-.3-1.4.2-.3.4-.3 1.1.2 1.4l4.8 3.8c.2.1.4.2.6.2.3 0 .6-.1.8-.4.3-.4.3-1.1-.2-1.4zM12 2.3C6 2.3 1.1 7.1 1.1 13.1 1.1 19.1 6 24 12 24s10.9-4.9 10.9-10.9S18 2.3 12 2.3zM12 22c-4.9 0-8.9-4-8.9-8.9s4-8.9 8.9-8.9 8.9 4 8.9 8.9-4 8.9-8.9 8.9zm.5-8.9V7.7c0-.6-.4-1-1-1s-1 .4-1 1v5.7c0 .3.1.5.3.7l3.8 3.8c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-3.5-3.4z',
  'clock-small':
    'M7.5 0C11.6421356 0 15 3.3578644 15 7.5S11.6421356 15 7.5 15 0 11.6421356 0 7.5 3.3578644 0 7.5 0zm0 1.5c-3.3137085 0-6 2.6862915-6 6s2.6862915 6 6 6 6-2.6862915 6-6-2.6862915-6-6-6zM8.25 3v4.598l2.666 1.778-.832 1.248L6.75 8.401V3z',
  calendar:
    'M22.9 2.6h-3V1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.3H13V1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.3H6.2V1.2c0-.6-.4-1-1-1s-1 .4-1 1v1.3h-3c-.6 0-1 .4-1 1v19.3c0 .6.4 1 1 1H23c.6 0 1-.4 1-1V3.6c-.1-.6-.6-1-1.1-1zm-18.7 2v1.3c0 .6.4 1 1 1s1-.4 1-1V4.6H11v1.3c0 .6.4 1 1 1s1-.4 1-1V4.6h4.9v1.3c0 .6.4 1 1 1s1-.4 1-1V4.6h2V8H2.1V4.6h2.1zM2.1 21.9v-12h19.8v12H2.1z',
  mail: 'M24 4.9v-.2c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1 0-.1-.1-.1-.2-.1l-.1-.1H.6c-.1 0-.1.1-.1.1-.1 0-.1.1-.2.1 0 0 0 .1-.1.1 0 .1-.1.1-.1.2v.2C0 4.9 0 5 0 5v14.7c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V5v-.1zM20.2 6L12 12.9 3.8 6h16.4zM2 18.7V7.1l9.3 7.8c.1.1.1.1.2.1.1.1.3.1.4.1.1 0 .3 0 .4-.1.1 0 .2-.1.2-.1L22 7.1v11.5H2z',
  'mail-small':
    'M20 6v1.6l-6.3 4.8c-.4.3-.9.4-1.5.4-.5 0-1.1-.1-1.5-.4L4 7.3V6h16zm-1.6 4.5L20 9.2v9.2H4V8.9l1.6 1.3v5.9l3-3.8 1.3.9L7 16.9h10l-2.7-3.4 1.3-.9 2.8 3.5v-5.6zm-.8-3H6.8l4.9 3.6c.3.2.8.2 1.1 0l4.8-3.6z',
  pencil:
    'M22.6 1.3c-1-1-2.3-1.4-3.9-1.1-1.3.2-2.7.9-3.6 1.8L3.2 13.9c-.1.1-.1.2-.2.3v.1L.3 22.5c-.1.4 0 .8.2 1 .2.2.4.3.7.3.1 0 .2 0 .3-.1L9.7 21h.1c.1 0 .2-.1.3-.2L22 8.9c1-.9 1.6-2.3 1.8-3.6.2-1.6-.2-3-1.2-4zM5.3 14.6L15.8 4.1l1.3 1.3L6.6 15.9l-1.3-1.3zM19 2.2c.5-.1 1.5-.1 2.2.6.7.7.7 1.7.6 2.2-.1.6-.3 1.2-.6 1.7l-3.9-3.9c.5-.3 1.1-.5 1.7-.6zM2.8 21.3l1.6-4.8 3.2 3.2-4.8 1.6zm6.6-2.6L8 17.3 18.5 6.8l1.4 1.4L9.4 18.7z',
  qualityspareparts:
    'M11.2 8.7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z M11.1 19.3c.1-.8.4-1.5.7-2.1-.9.1-1.8 0-2.7-.2l-.7-.2-1.2 1.9-1.4-.8 1-2.1-.5-.5c-.9-.8-1.5-2-1.8-3.2l-.2-.6-2.3-.1V9.8l2.3-.1.4-.7c.3-1.2.9-2.3 1.8-3.2l.5-.5-1-2c.4-.3.9-.6 1.3-.7l1.3 1.8.5-.2c1.3-.4 2.5-.4 3.7 0l.7.2 1.2-1.9 1.4.8-1 2.1.5.5c.9.8 1.5 2 1.8 3.2l.2.6 2.2.2v1.6l-2.3.1-.2.6c-.1.3-.2.6-.3.8.6-.2 1.3-.2 1.9-.2.9 0 1.7.1 2.5.4h.2l.1-.7c.1-.7.2-1.3.2-1.9 0-.6-.1-1.2-.2-1.9V8l-2.4-.1c-.4-1.1-1-2.2-1.8-3l1.1-2.2-.6-.5c-.9-.8-2.1-1.4-3.3-1.9L14 0l-1.3 2.1c-1.1-.3-2.3-.3-3.5 0L8 0l-.7.3C6.1.7 5 1.3 4 2.2l-.5.4 1 2.2c-.8.8-1.4 1.9-1.8 3L.3 8l-.1.7C.1 9.4 0 10 0 10.6c0 .6.1 1.2.2 1.9l.2.7 2.4.1c.4 1.1 1 2.2 1.8 3l-1.1 2.2.5.5c.9.8 2.1 1.4 3.3 1.9l.7.3 1.3-2.1c.6.1 1.2.2 1.8.2zM17.9 24c-.3 0-.6-.1-.8-.4l-2.5-3.2c-.3-.4-.3-1.1.2-1.4.4-.3 1.1-.3 1.4.2l1.8 2.2 4.3-5.4c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4l-5.1 6.4c-.3.3-.6.4-.9.4z',
  tool: 'M3.9 24c-1 0-2-.4-2.7-1.1-1.5-1.5-1.5-4 0-5.5l.1-.1 9.3-8.7c-.6-2.3 0-4.9 1.8-6.6 1.4-1.4 3.4-2.2 5.4-2 .6 0 1.1.4 1.3 1 .2.5.1 1.2-.3 1.6L16.2 5l.7 2.1 2.1.6 2.4-2.4c.4-.4 1-.5 1.6-.3.5.2.9.7 1 1.3.2 2-.5 4-2 5.4-1.7 1.7-4.3 2.4-6.6 1.8l-8.7 9.4c-.8.7-1.8 1.1-2.8 1.1zm-1.3-5.2c-.7.7-.7 1.9 0 2.6.7.7 1.9.7 2.6 0l8.9-9.6.2-.2.5-.6.7.3c1.8.7 3.8.2 5.2-1.1.7-.7 1.2-1.7 1.4-2.7L19.5 10l-4.2-1.3L14 4.5l2.4-2.4c-1 .2-1.9.6-2.7 1.4-1.3 1.3-1.8 3.4-1.1 5.2l.2.7-.5.5-.2.2-9.5 8.7zM21.9 6.4z',
  softwareupdate:
    'M13.8 19.8c-.6.1-1.2.2-1.8.2-4.4 0-8-3.6-8-8 0-1.7.5-3.3 1.5-4.6l.5 2.8c.1.5.5.8 1 .8h.2c.5-.1.9-.6.8-1.2l-1-5c-.1-.5-.7-.9-1.2-.8l-5 1c-.5.1-.9.7-.8 1.2.1.5.7.9 1.2.8l2.5-.5C2.6 8.1 2 10 2 12c0 5.5 4.5 10 10 10 .7 0 1.5-.1 2.2-.2.5-.1.9-.7.8-1.2-.1-.6-.7-.9-1.2-.8zM22.8 17l-2.5.5C21.4 15.9 22 14 22 12c0-5.5-4.5-10-10-10-.7 0-1.5.1-2.2.2-.6.2-.9.7-.8 1.2.1.6.7.9 1.2.8.6-.1 1.2-.2 1.8-.2 4.4 0 8 3.6 8 8 0 1.7-.5 3.3-1.5 4.6l-.5-2.8c-.1-.5-.6-.9-1.2-.8-.5.1-.9.6-.8 1.2l1 5c.1.5.5.8 1 .8h.2l5-1c.5-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8z',
  mobilitywarranty:
    'M20 22.7l-3.1-10.3c.1-.1.2-.1.2-.2.6-.6.7-1.3.7-1.9 0-.3.1-.5.1-.7.1-.1.2-.3.4-.5.4-.5.9-1.1.9-1.9 0-.8-.5-1.4-.9-1.9-.2-.2-.3-.4-.4-.5-.1-.2-.1-.4-.1-.7-.1-.6-.2-1.4-.7-1.9-.6-.6-1.3-.7-1.9-.7-.3 0-.5-.1-.7-.1-.1-.1-.3-.2-.5-.4-.6-.5-1.2-1-2-1s-1.4.5-1.9.9c-.2.1-.4.3-.6.4-.1 0-.4.1-.7.1-.6.1-1.3.1-1.9.7S6.3 3.4 6.2 4c0 .3-.1.5-.1.7-.1.2-.2.4-.4.6-.4.5-.9 1.1-.9 1.9 0 .8.5 1.4.9 1.9.2.2.3.4.4.5.1.2.1.4.1.7.1.6.2 1.4.7 1.9.1.1.2.1.2.2L4 22.7c-.1.3 0 .7.2.9.3.3.6.4.9.4l6.9-1 6.9 1h.1c.3 0 .6-.1.8-.3.2-.3.3-.7.2-1zM6.8 7.2c0-.1.3-.5.4-.7.2-.3.5-.6.7-1 .2-.4.2-.9.3-1.2 0-.2.1-.6.1-.7.1-.1.5-.1.7-.1.4 0 .8-.1 1.2-.3.4-.2.7-.4 1-.7.3-.2.7-.5.8-.5.1 0 .5.3.7.4.3.2.6.5 1 .7.4.2.9.2 1.2.3.2 0 .6.1.7.1.1.1.1.5.1.7 0 .4.1.8.3 1.2.2.4.4.7.7 1 .2.2.4.5.4.7s-.3.5-.4.7c-.2.3-.5.6-.7 1-.2.4-.2.9-.3 1.2 0 .2-.1.6-.1.7-.1.1-.5.1-.7.1-.4 0-.8.1-1.2.3-.4.2-.7.4-1 .7-.2.2-.5.4-.7.4-.1 0-.5-.3-.7-.4-.3-.2-.6-.5-1-.7-.4 0-.8-.1-1.2-.1-.2 0-.6-.1-.7-.1-.1-.1-.1-.5-.1-.7 0-.4-.1-.8-.3-1.2-.2-.4-.4-.7-.7-1-.2-.4-.5-.7-.5-.8zm6 13.9c.1-.2.2-.4.2-.6V19c0-.6-.4-1-1-1s-1 .4-1 1v1.5c0 .2.1.4.2.6l-4.8.7L9.1 13c.2 0 .4.1.5.1.1.1.3.2.5.4.3.3.8.6 1.3.8-.3.1-.4.4-.4.7v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.3-.1-.6-.3-.7.5-.2.9-.5 1.3-.8.2-.2.4-.3.5-.4.1 0 .3-.1.5-.1l2.7 8.8-4.9-.7z',
  personquaified:
    'M12 10.8c-3 0-5.4-2.4-5.4-5.4S9 0 12 0c3 0 5.4 2.4 5.4 5.4S15 10.8 12 10.8zM12 2c-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4 3.4-1.5 3.4-3.4S13.9 2 12 2zM14.6 14.9c.7-.6 1.5-1.2 2.4-1.5-1-.3-2.2-.5-3.3-.5h-3.3C4.7 12.9 0 17.2 0 22.5v.5c0 .6.4 1 1 1s1-.4 1-1v-.5c0-4.2 3.8-7.7 8.4-7.7h3.3c.3.1.6.1.9.1zM17.9 24c-.3 0-.6-.1-.8-.4l-2.5-3.2c-.3-.4-.3-1.1.2-1.4.4-.3 1.1-.3 1.4.2l1.8 2.2 4.3-5.4c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4l-5.1 6.4c-.3.2-.6.4-.9.4z',
  threedots:
    'M10 2c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm0 10c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm0 10c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z',
  cloud:
    'M14.2 5c2.6 0 4.7 2.1 4.7 4.7l-.1 1.3 1.1.7c1.2.7 2 2 2 3.4 0 2.2-1.8 5-4 5H6c-2.2 0-4-2.8-4-5 0-1.4.8-2.7 2-3.4l1.1-.7L5 9.7C5 8.7 5.8 8 6.8 8c.2 0 .5.1.7.2l1.7.8.9-1.6C11 5.9 12.5 5 14.2 5m0-2c-2.5 0-4.7 1.4-5.8 3.4-.5-.2-1-.4-1.6-.4C4.7 6 3 7.7 3 9.8v.1c-1.8 1-3 3-3 5.2 0 3.3 2.7 7 6 7h12c3.3 0 6-3.7 6-7 0-2.2-1.2-4.1-3-5.2v-.1C21 6 18 3 14.2 3z M10.2 18c-.3 0-.6-.1-.8-.4l-2.5-3.2c-.3-.4-.3-1.1.2-1.4.4-.3 1.1-.3 1.4.2l1.8 2.2 4.3-5.4c.3-.4 1-.5 1.4-.2.4.3.5 1 .2 1.4L11 17.6c-.2.2-.5.4-.8.4z',
  upload:
    'M23 24H1c-.6 0-1-.4-1-1v-7.3c0-.6.4-1 1-1s1 .4 1 1V22h20v-6.3c0-.6.4-1 1-1s1 .4 1 1V23c0 .6-.4 1-1 1zM4.9 9.1c.4.3 1.1.3 1.4-.1L11 3.7v14.8c0 .6.4 1 1 1s1-.4 1-1V3.7L17.7 9c.4.4 1 .5 1.4.1.4-.4.5-1 .1-1.4L12.8.4c-.2-.3-.5-.4-.8-.4s-.6.1-.8.3L4.8 7.7c-.3.4-.3 1 .1 1.4z',
  trash:
    'M17.8 6.5c-.6 0-1 .4-1 1v14.2H7.2V7.5c0-.6-.4-1-1-1s-1 .4-1 1v15.2c0 .6.4 1 1 1h11.6c.6 0 1-.4 1-1V7.5c0-.5-.4-1-1-1zm-7 12.7V7.5c0-.6-.4-1-1-1s-1 .4-1 1v11.6c0 .6.4 1 1 1s1-.4 1-.9zm4.4 0V7.5c0-.6-.4-1-1-1s-1 .4-1 1v11.6c0 .6.4 1 1 1s1-.4 1-.9zm5.3-15.4h-3L16 .8c-.2-.3-.5-.6-.9-.6H8.9c-.4.1-.8.3-.9.6l-1.5 3h-3c-.6 0-1 .4-1 1s.4 1 1 1h17c.6 0 1-.4 1-1s-.4-1-1-1zm-11-1.5h5l.8 1.6H8.7l.8-1.6z',
  foreward:
    'M23.5,11.7c0.1,0.2,0.1,0.4,0,0.5l-5,5.1c-0.3,0.1-0.6,0.1-0.8,0c-0.2-0.3-0.1-0.7,0.1-0.9l4.3-4.5l-4.4-4.6c-0.1-0.3-0.1-0.6,0-0.8c0.3-0.1,0.6-0.1,0.8,0L23.5,11.7L23.5,11.7z',
  backward:
    'M.6 11.73l5-5.12a.83.83 0 0 1 .82 0 1 1 0 0 1 0 .84L2 12l4.3 4.45a.84.84 0 0 1 .1.94.83.83 0 0 1-.82 0l-5-5.12a.41.41 0 0 1 0-.54z',
  folder:
    'M23 22H1c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1h8c.5 0 .9.3 1 .8l.8 3.2H23c.6 0 1 .4 1 1v14c0 .6-.4 1-1 1zM2 20h20V8H10c-.5 0-.9-.3-1-.8L8.2 4H2v16z',
  folderup:
    'M23 20.8H8.9V4.3l5.2 6.1c.4.4 1 .5 1.4.1.4-.4.5-1 .1-1.4L8.7.9C8.3.5 7.6.5 7.2.9L.2 9c-.4.4-.3 1.1.1 1.4.2.2.4.2.7.2.3 0 .6-.1.8-.3L7 4.2v17.6c0 .6.4 1 1 1h15c.6 0 1-.4 1-1s-.4-1-1-1z',
  picture:
    'M23 3H1c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1zm-1 16H2v-3.4l3.1-3.8L8 14.1l2-1.5 2.8 2.8H22V19zm0-4.5h-8.8L10 11.3l-2 1.5-3.1-2.6L2 13.9V5h20v9.5zm-5-3c1.4 0 2.5-1.1 2.5-2.5S18.4 6.5 17 6.5 14.5 7.6 14.5 9s1.1 2.5 2.5 2.5zm0-4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5z',
  headline: 'M16.1 20.9V13H7.9v7.9H5.2V3.1h2.7v7.2h8.2V3.1h2.7v17.8h-2.7z',
  quote:
    'M4 22.5c-.6 0-1-.4-1-1v-6.1c0-.6.4-1 1-1s1 .4 1 1v6.1c0 .6-.4 1-1 1zm5 0c-.6 0-1-.4-1-1v-6.1c0-.6.4-1 1-1s1 .4 1 1v6.1c0 .6-.4 1-1 1zm6-13c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6.1c0 .5-.4.9-1 .9zm5 0c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6.1c0 .5-.4.9-1 .9z',
  list: 'M20 6H10c-.6 0-1-.4-1-1s.4-1 1-1h10c.6 0 1 .4 1 1s-.4 1-1 1zM6 5c0 .8-.7 1.5-1.5 1.5S3 5.8 3 5c0-.4.1-.7.4-1 .3-.3.7-.5 1.1-.5.8 0 1.5.7 1.5 1.5zm14 8H10c-.6 0-1-.4-1-1s.4-1 1-1h10c.6 0 1 .4 1 1s-.4 1-1 1zM6 12c0 .8-.7 1.5-1.5 1.5S3 12.8 3 12s.7-1.5 1.5-1.5c.4 0 .7.1 1 .4.3.3.5.7.5 1.1zm14 8H10c-.6 0-1-.4-1-1s.4-1 1-1h10c.6 0 1 .4 1 1s-.4 1-1 1zM6 19c0 .8-.7 1.5-1.5 1.5S3 19.8 3 19s.7-1.5 1.5-1.5c.4 0 .8.2 1.1.4.2.3.4.7.4 1.1z',
  rewards:
    'M21.1 3h-2.4V1.8c0-1-.9-1.8-1.9-1.8H7.3C6.2 0 5.4.8 5.4 1.8V3H2.9C1.9 3 1 3.8 1 4.8v3.1c0 2.7 2.3 5 5.2 5.1 1 1.8 2.7 3.1 4.8 3.4v2.2c-2.2.4-4 2-4.9 4.2-.1.2-.1.6.1.8.2.3.5.4.8.4h10c.3 0 .6-.1.8-.4.2-.2.2-.5.1-.8-1-2.3-2.7-3.9-4.9-4.2v-2.2c2.1-.3 3.9-1.6 4.9-3.5 2.9-.1 5.1-2.4 5.1-5.1v-3c0-1-.9-1.8-1.9-1.8zM3 7.9V5h2.4v4.7c0 .4 0 .8.1 1.2-1.4-.4-2.5-1.6-2.5-3zm12.4 14.3H8.6c.8-1.2 2.1-1.9 3.4-1.8 1.4 0 2.5.6 3.4 1.8zm1.3-12.3c0 2.6-2 4.8-4.6 4.8-2.6 0-4.8-2.3-4.8-5V1.8l9.4.1v8zm4.3-2c0 1.4-1 2.6-2.4 3 .1-.3.1-.7.1-1.1V5H21v2.9z',
  plus: 'M17 11h-4V7c0-.6-.4-1-1-1s-1 .4-1 1v4H7c-.6 0-1 .4-1 1s.4 1 1 1h4v4c0 .6.4 1 1 1s1-.4 1-1v-4h4c.6 0 1-.4 1-1s-.4-1-1-1z M12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10S2 17.5 2 12 6.5 2 12 2m0-2C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.7 0 12 0z',
  engine:
    'M23 7c-.6 0-1 .4-1 1v1h-.4l-3.1-3.4V4c0-.5-.5-1-1-1H4.7c-.6 0-1 .4-1 1v1.6L2.4 7H1c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h1.4l3.4 3.7c.1.2.4.3.7.3h12.8c.6 0 1-.4 1-1v-3H22v1c0 .6.4 1 1 1s1-.4 1-1V8c0-.6-.4-1-1-1zm-3.7 8c-.6 0-1 .4-1 1v3H6.9l-3.4-3.7c-.1-.2-.4-.3-.7-.3H2V9h.8c.3 0 .5-.1.7-.3l1.8-2c.3-.2.4-.5.4-.7V5h10.8v1c0 .2.1.5.3.7l3.7 4c.2.2.5.3.7.3h.8v4h-2.7z M16 12h-1.2c0-.1-.1-.2-.1-.3l1-1c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1 1c-.1 0-.2-.1-.3-.1V9c0-.5-.4-1-1-1s-1 .4-1 1v1.2c-.1 0-.2.1-.3.1l-1-1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1 1c0 .1-.1.2-.1.3H8c-.5 0-1 .4-1 1s.4 1 1 1h1.2c0 .1.1.2.1.3l-1 1c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l1-1c.1 0 .2.1.3.1V17c0 .5.4 1 1 1s1-.4 1-1v-1.2c.1 0 .2-.1.3-.1v.1l1 1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-1-1h-.1c0-.1.1-.2.1-.3H16c.6 0 1-.4 1-1s-.4-1.1-1-1.1zm-4 2c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.5 1-1 1z',
  vario1:
    'M5.63 0L7.5 3.75H3.75L5.63 0zM8.03 10.56l-1.06.58-.44-.84 1.61-.92h.96v5.65H8.03v-4.47z M13.12 20h-10a3.08 3.08 0 0 1-3.08-3.08V6.83a3.08 3.08 0 0 1 3.08-3.08h10a3.08 3.08 0 0 1 3.08 3.08v10.09A3.08 3.08 0 0 1 13.12 20zM3.23 5.62A1.36 1.36 0 0 0 1.87 7v9.79a1.35 1.35 0 0 0 1.36 1.35h9.78a1.35 1.35 0 0 0 1.36-1.35V7a1.36 1.36 0 0 0-1.36-1.36z',
  vario2:
    'M20 5.63L16.25 7.5V3.75L20 5.63z M0 13.12v-10A3.08 3.08 0 0 1 3.08 0h10.09a3.08 3.08 0 0 1 3.08 3.12v10a3.08 3.08 0 0 1-3.08 3.08H3.08A3.08 3.08 0 0 1 0 13.12zm14.38-9.89A1.36 1.36 0 0 0 13 1.87H3.23a1.35 1.35 0 0 0-1.35 1.36v9.78a1.35 1.35 0 0 0 1.35 1.36H13a1.36 1.36 0 0 0 1.36-1.36z M6.44 10.99v-.87c.67-.72.75-.81 1.36-1.51.82-1 .95-1.1.95-1.67V6.8c0-.43-.17-.55-.81-.55a6.93 6.93 0 0 0-1.38.17l-.15-.89A5.65 5.65 0 0 1 8 5.25c1.35 0 1.82.46 1.82 1.37v.2c0 .81-.07 1-.89 2-.49.61-.7.85-1 1.24h1.95V11z',
  vario3:
    'M10.62 20l-1.87-3.75h3.75L10.62 20z M3.13 0h10a3.09 3.09 0 0 1 3.09 3.08v10.09a3.08 3.08 0 0 1-3.09 3.08h-10a3.09 3.09 0 0 1-3.09-3.08V3.08A3.08 3.08 0 0 1 3.13 0zm9.93 14.38A1.36 1.36 0 0 0 14.38 13V3.23a1.35 1.35 0 0 0-1.36-1.35H3.24a1.35 1.35 0 0 0-1.36 1.35V13a1.36 1.36 0 0 0 1.36 1.36z M6.46 11.11l.11-.88a6 6 0 0 0 1.25.13c.77 0 .91-.16.91-.61v-.34c0-.43-.19-.56-.63-.56H6.85v-1h1.06c.59 0 .72-.2.72-.61V6.9c0-.42-.19-.53-.81-.53a6.33 6.33 0 0 0-1.2.12l-.12-.9a6.1 6.1 0 0 1 1.42-.16c1.37 0 1.78.45 1.78 1.29v.54a1 1 0 0 1-.63 1.06 1 1 0 0 1 .73 1.06v.5c0 .87-.43 1.38-1.94 1.38a5.26 5.26 0 0 1-1.4-.15z',
  'arrow-small-up':
    'M5.6 9.1l5.1 5c.1.1.1.7 0 .8s-.7.1-.8 0l-4.5-4.4L1 14.8c-.3.3-.8.2-.9.1-.1-.1-.1-.7 0-.8l5.1-5c-.1-.1 0-.1.1-.1s.2 0 .3.1z',
  'arrow-small-down':
    'M5.2 14.9l-5.1-5c-.1-.1-.1-.7 0-.8.2-.1.7-.1.9 0l4.5 4.4 4.4-4.3c.3-.3.8-.2.9-.1.1.1.1.7 0 .8l-5.1 5c0 .1-.1.1-.2.1s-.2 0-.3-.1z',
  footnote:
    'M18.8,4.1l-5.4,7v0.1l8.5-1.1v3.8l-8.5-1V13l5.4,6.8l-3.5,2L12,13.9h-0.1l-3.7,7.9l-3.2-2l5.4-6.9v-0.1L2,13.9v-3.8l8.3,1.1V11L5,4.2l3.4-2l3.5,7.8H12l3.4-7.9L18.8,4.1z',
  faq: 'M12.5 5C10 5 8 7 8 9.5c0 .6.4 1 1 1s1-.4 1-1C10 8.1 11.1 7 12.5 7S15 8.1 15 9.5 13.9 12 12.5 12c-.1 0-.2 0-.2.1-.1-.1-.2-.1-.3-.1-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-1.1c2.2-.3 4-2.1 4-4.4C17 7 15 5 12.5 5zM12 17c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1c0-.3.1-.5.3-.7.1-.2.4-.3.7-.3z M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 22C6.5 22 2 17.5 2 12c0-3 1.4-5.8 3.5-7.6C7.3 2.9 9.5 2 12 2c5.5 0 10 4.5 10 10s-4.5 10-10 10z',
  check:
    'M8.3 22.2c-.3 0-.6-.1-.8-.4L.2 12.6c-.4-.4-.3-1.1.2-1.4.4-.4 1.1-.3 1.4.2l6.5 8.2L22.2 2.2c.4-.4 1-.5 1.4-.2.4.4.5 1 .2 1.4L9.1 21.8c-.2.2-.5.4-.8.4z',
  bold: 'M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z',
  sub: 'm3.094 17 4.892-7.471-4.433-6.845h3.379l2.871 4.599 2.812-4.599h3.35l-4.453 6.953L16.404 17h-3.486l-3.174-4.951L6.561 17zM19.92 18.201c-.17-.202-.41-.303-.723-.303-.423 0-.71.157-.86.47-.09.181-.142.471-.155.868h-1.358c.026-.599.137-1.084.332-1.455.371-.71 1.029-1.064 1.973-1.064.749 0 1.344.208 1.787.625.443.416.664.967.664 1.65 0 .521-.156.987-.469 1.397-.201.267-.537.566-1.006.898l-.556.4c-.352.248-.591.427-.718.538-.127.11-.236.237-.327.38H21.6v1.23h-4.854c.013-.507.124-.972.332-1.396.195-.468.664-.966 1.406-1.494.638-.462 1.052-.79 1.24-.986.294-.313.44-.651.44-1.016 0-.3-.081-.547-.244-.742Z',
  'arrow-left':
    'M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24v-1z',
  'arrow-right':
    'm17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z',
  closerounded: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      focusable="false"
    >
      <path
        d="M26 1L1 26"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 26L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  'social-twitter': (
    <svg
      fill="none"
      height="2260"
      width="2500"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.254 0.25 500 451.95400000000006"
      focusable="false"
    >
      <path
        d="M394.033.25h76.67L303.202 191.693l197.052 260.511h-154.29L225.118 294.205 86.844 452.204H10.127l179.16-204.77L.254.25H158.46l109.234 144.417zm-26.908 406.063h42.483L135.377 43.73h-45.59z"
        fill="currentColor"
      />
    </svg>
  ),
  'social-linkedin': (
    <svg
      height="2500"
      width="2490"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      focusable="false"
    >
      <g fill="none">
        <path
          d="M0 18.338C0 8.216 8.474 0 18.92 0h218.16C247.53 0 256 8.216 256 18.338v219.327C256 247.79 247.53 256 237.08 256H18.92C8.475 256 0 247.791 0 237.668V18.335z"
          fill="currentColor"
        />
        <path
          d="M77.796 214.238V98.986H39.488v115.252H77.8zM58.65 83.253c13.356 0 21.671-8.85 21.671-19.91-.25-11.312-8.315-19.915-21.417-19.915-13.111 0-21.674 8.603-21.674 19.914 0 11.06 8.312 19.91 21.169 19.91h.248zM99 214.238h38.305v-64.355c0-3.44.25-6.889 1.262-9.346 2.768-6.885 9.071-14.012 19.656-14.012 13.858 0 19.405 10.568 19.405 26.063v61.65h38.304v-66.082c0-35.399-18.896-51.872-44.099-51.872-20.663 0-29.738 11.549-34.78 19.415h.255V98.99H99.002c.5 10.812-.003 115.252-.003 115.252z"
          fill="#000"
        />
      </g>
    </svg>
  ),
  'social-tiktok': (
    <svg
      height="797.73mm"
      viewBox="-58.35000000000002 -186.70564362582354 2548.289756960746 2538.849821747569"
      width="705.56mm"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
    >
      <path
        d="M1609.26 433.36a514.19 514.19 0 0 1-127.84-339.47h-99.68a517.16 517.16 0 0 0 227.52 339.47zM686.93 1167.9a313.46 313.46 0 0 0-144.46 590.81A312.75 312.75 0 0 1 796 1262.51a329.69 329.69 0 0 1 92.44 14.49V897.05a654.77 654.77 0 0 0-92.44-7.22h-16.62v288.9a321.13 321.13 0 0 0-92.45-10.83z"
        fill="currentColor"
      />
      <path
        d="M1891.66 601.64v288.91a886.23 886.23 0 0 1-517.86-168.29v759.1c-.8 378.78-308.09 685.43-686.87 685.43A679.65 679.65 0 0 1 294 2042.56 685.43 685.43 0 0 0 1481.42 1576V819.05A887.71 887.71 0 0 0 2000 985.17v-372a529.59 529.59 0 0 1-108.34-11.53z"
        fill="currentColor"
      />
      <path
        d="M1373.8 1481.36v-759.1a886.11 886.11 0 0 0 518.58 166.12v-288.9a517.87 517.87 0 0 1-283.12-166.12 517.16 517.16 0 0 1-227.52-339.47h-273V1589a313.46 313.46 0 0 1-567 171.17 313.46 313.46 0 0 1 144.46-590.83 321.35 321.35 0 0 1 92.45 14.45V894.88A684.71 684.71 0 0 0 293.29 2050.5a679.65 679.65 0 0 0 393.64 116.29c378.78 0 686.07-306.65 686.87-685.43z"
        fill="currentColor"
      />
    </svg>
  ),
};

export default class Icon extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    inline: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    inline: true,
  };

  render() {
    const { className: propsClassName, inline, name } = this.props;

    const className = classNames(styles.icon, propsClassName, {
      [styles.inline]: inline,
    });

    return (
      <span className={className}>
        {typeof icons[name] === 'string' ? (
          <svg width="24" height="24" viewBox="0 0 24 24" focusable="false">
            <path fillRule="evenodd" fill="currentColor" d={icons[name]} />
          </svg>
        ) : (
          icons[name]
        )}
      </span>
    );
  }
}
