// @flow
import BaseModel from 'Stores/BaseModel';

export default class ListModel {
  items = [];

  constructor(items: ?Array<Object>, modelClass: ?any, inject: ?Object) {
    if (items) {
      this.items = items.map((item) => {
        if (inject) {
          Object.assign(item, inject);
        }

        return new modelClass(item);
      });
    } else {
      this.items = [];
    }
  }

  getItemById = (id: number) => {
    return this.items.find((item: BaseModel) => item.id === id);
  };

  getItemByIndex = (index: number) => {
    return this.items[index];
  };

  addItem = (item: BaseModel) => {
    return this.items.unshift(item);
  };

  addItemAtEnd = (item: BaseModel) => {
    return this.items.push(item);
  };

  updateItemById = (id: number, item: BaseModel) => {
    const index = this.items.findIndex((n) => n.id === id);
    if (index >= 0) {
      this.items[index] = item;
    }
  };

  removeItemById = (id: number) => {
    this.items = this.items.filter((n) => n.id !== id);
  };

  addOrUpdate = (item: BaseModel, options: { atEnd: boolean } = { atEnd: false }) => {
    if (item && item.id > 0) {
      if (this.getItemById(item.id)) {
        // update
        this.updateItemById(item.id, item);
      } else {
        // add
        if (options && options.atEnd) {
          this.addItemAtEnd(item);
        } else {
          this.addItem(item);
        }
      }
    }
  };

  reduce = (...params: Array<any>): Array<any> => {
    return this.items.reduce(...params);
  };

  map = (fn: (any) => any): Array<any> => {
    return this.items.map(fn) || [];
  };

  filter = (fn: (any) => any): ListModel => {
    const items = this.items.filter(fn);
    const list = new ListModel();
    list.items = items;
    return list;
  };

  sortByProp = (prop: string) => {
    this.items = this.items.sort((a: BaseModel, b: BaseModel) => {
      let propA = '',
        propB = '';

      if (typeof a[prop] === typeof b[prop] && typeof a[prop] === 'string') {
        propA = a[prop].toUpperCase();
        propB = b[prop].toUpperCase();
      } else if (typeof a[prop] === typeof b[prop] && typeof a[prop] === 'number') {
        propA = a[prop];
        propB = b[prop];
      }

      if (propA < propB) {
        return -1;
      }
      if (propA > propB) {
        return 1;
      }
      return 0;
    });

    return this;
  };

  moveItemCategory = (category, target) => {
    if (target === 'end') {
      this.items = [
        ...this.items.filter((item) => item.offerCategory !== category),
        ...this.items.filter((item) => item.offerCategory === category),
      ];
    }
  };

  toListItems = (): BaseModel[] => {
    return this.items.map((entry) => {
      return entry.toListItem();
    });
  };

  empty = () => {
    return this.items.length === 0;
  };

  groupBy = (field: string, sorting: ?Array<number>): { key: string, items: ListModel }[] => {
    const items = this.items.reduce((groups, item) => {
      const val = item[field];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});

    const result = Object.keys(items).map((key) => {
      const list = new ListModel();
      list.items = items[key];
      return {
        key,
        items: list,
      };
    });

    if (sorting) {
      result.sort((a, b) => {
        return sorting.indexOf(parseInt(a.key, 0)) > sorting.indexOf(parseInt(b.key, 0)) ? 1 : -1;
      });
    }

    return result;
  };
}
