// @flow
import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import DealerModel from 'Stores/DealerModel';
import { leadTracking } from 'Utils/trackingAttributes';
import { MODAL_TYPES } from 'Containers/ContactForm/contactFormConstants';
import { HintUsedCarValuation } from './HintUsedCarValuation';

const cx = classNames.bind({ ...styles });

type Props = {
  dealer: DealerModel,
  handleFlyout: () => void,
  onOpenContact: () => void,
  onOpenConfigurator: () => void,
};

type State = {
  showOverlayHint: boolean,
};

export default class Lead extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showOverlayHint: false,
    };
  }

  openOverlayHint = (state: State) => {
    this.setState(state);
  };

  closeOverlayHint = () => {
    this.setState({ showOverlayHint: false });
  };

  render() {
    const { dealer, onOpenContact, onOpenConfigurator, handleFlyout, forceMobileMenu } = this.props;
    const dfmm = { 'data-force-mobile-menu': forceMobileMenu };
    const { showOverlayHint } = this.state;

    return (
      <ul className={cx('lead-nav')} {...dfmm}>
        {dealer.isServicePartnerOnly() && !dealer.settings.isOsbUser ? null : (
          <li className={cx('lead-nav__item')} {...dfmm}>
            <a
              href="#"
              onClick={(evt) => {
                evt.preventDefault();
                handleFlyout({ id: -666 });
                this.props.updateMultipleUrlQuery({
                  modaltype: MODAL_TYPES.REQUEST_FOR_CONSULTATION_FK.name,
                  showmodal: 'true',
                });
              }}
              {...leadTracking((items) => items.Beratungstermin)}
            >
              {dealer.settings.isOsbUser || dealer.isSalesPartnerOnly()
                ? 'Individuelle Beratung'
                : 'Terminanfrage'}
            </a>
          </li>
        )}

        {!dealer.isSalesPartnerOnly() &&
          !(
            !dealer.settings.isOsbUser &&
            dealer.settings.isSalesPartner &&
            dealer.settings.isServicePartner
          ) && (
            <li className={cx('lead-nav__item')} {...dfmm}>
              <a
                href="/werkstatttermin/"
                onClick={(evt) => {
                  evt.preventDefault();

                  handleFlyout({ id: -666 });
                  this.props.updateMultipleUrlQuery({
                    modaltype: MODAL_TYPES.ONLINE_SERVICE_BOOKING.name,
                    showmodal: 'true',
                  });
                }}
                {...leadTracking((items) => items.Werkstatttermin)}
              >
                {dealer.settings.isOsbUser ? 'Werkstatttermin' : 'Terminanfrage'}
              </a>
            </li>
          )}
        {dealer.settings.isSalesPartner && (
          <Fragment>
            <li className={cx('lead-nav__item')} {...dfmm}>
              <a
                href="/probefahrt/"
                onClick={(evt) => {
                  evt.preventDefault();

                  handleFlyout({ id: -666 });
                  onOpenContact(2);
                }}
                {...leadTracking((items) => items.Probefahrt)}
              >
                Probefahrt
              </a>
            </li>
            <li className={cx('lead-nav__item')} {...dfmm}>
              <a
                href="/angebotsanfrage/"
                onClick={(evt) => {
                  evt.preventDefault();

                  handleFlyout({ id: -666 });
                  onOpenContact(0);
                }}
                {...leadTracking((items) => items.Angebotsanfrage)}
              >
                Angebotsanfrage
              </a>
            </li>
            {dealer.settings.showCarConfigurator ? (
              <li className={cx('lead-nav__item')} {...dfmm}>
                <Link
                  to="?modaltype=CAR_CONFIGURATOR&showmodal=true"
                  onClick={() => {
                    handleFlyout({ id: -666 });

                    if (onOpenConfigurator) {
                      onOpenConfigurator();
                    }
                  }}
                  {...leadTracking((items) => items.Konfigurator)}
                >
                  Konfigurator
                </Link>
              </li>
            ) : null}
          </Fragment>
        )}
        {dealer.settings.showUsedcarValuation && (
          <Fragment>
            <li className={cx('lead-nav__item')} {...dfmm}>
              <button
                className={cx('buttonHint')}
                onClick={() => this.openOverlayHint({ showOverlayHint: true })}
              >
                Gebrauchtwagenbewertung
              </button>
            </li>
            {showOverlayHint && (
              <HintUsedCarValuation
                isOpen={showOverlayHint}
                onClose={() => this.closeOverlayHint()}
                leadtracking={leadTracking((items) => items.Gebrauchtwagenbewertung)}
                flyout={() => handleFlyout({ id: -666 })}
              />
            )}
          </Fragment>
        )}
      </ul>
    );
  }
}

Lead.Admin = class extends Component {
  render() {
    const dfmm = { 'data-force-mobile-menu': this.props.forceMobileMenu };
    return <ul className={cx('lead-nav')} {...dfmm} />;
  }
};
