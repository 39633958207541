import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from 'Components/Layout';
import GenericContainer from 'Containers/GenericContainer';
import Analytics from 'Containers/Analytics';
import ErrorPage from 'Containers/ErrorPage';
import DealerModel from 'Stores/DealerModel';
import Alliance from 'Containers/Alliance';
import { CONTACT_TYPES } from '../ContactForm/contactFormConstants';
import ErrorBoundary from '../../components/ErrorBoundary';
import { hasCookie, getCookie } from 'Utils';
import GlobalContext from '../../context/Global/index';
import { Cars } from 'Containers/FMM';
import ScrollToTop from 'Components/FixedButtonScrollToTop';

const FixedButton = lazy(
  () => import(/* webpackChunkName: 'FixedButton' */ 'Components/FixedButton')
);
const Styleguide = lazy(
  () => import(/* webpackChunkName: 'Styleguide' */ '../Styleguide/component')
);
const DealerBackend = lazy(
  () => import(/* webpackChunkName: 'DealerBackend' */ '../DealerBackend/HiApp')
);
const ContactModal = lazy(
  () => import(/* webpackChunkName: 'ContactModal' */ 'Containers/ContactModal')
);
const ConfiguratorModal = lazy(
  () => import(/* webpackChunkName: 'ConfiguratorModal' */ 'Containers/ConfiguratorModal')
);
const CookieBanner = lazy(() => import('Components/CookieBanner'));

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.updateGlobalContext = (name, value, multipleValues) => {
      if (multipleValues) {
        this.setState({
          globalContext: { ...this.state.globalContext, ...multipleValues },
        });
      } else {
        this.setState({
          globalContext: { ...this.state.globalContext, [name]: value },
        });
      }
    };

    this.state = {
      globalContext: {
        selectedAccessoryPart: undefined,
        selectedAccessoryPartModel: undefined,
      },
      updateGlobalContext: this.updateGlobalContext,
      dealer: new DealerModel(),
      error: !window.dealer || window.dealer.error || (window.dealer.dealerId || '0') === '0',
      errorStatus: window.dealer.status || null,
      hwsconfig: window.hwsconfig || {},
      showContact: false,
      showCarConfigurator: false,
      contactType: 0,
      contactModel: '',
      contentLoading: false,
      // shared component state
      sharedState: {
        campaignCode: null,
        carModel: null,
        specialOffers: [],
        offers: [],
        footnotes_landingpage: [],
        modalType: null,
        clientType: null,
        lastSearchParams: null,
        cookieUpdated: false,
        isAccessoryConsumptionInfoClosed: false,
        forceMobileMenu: false,
        leadsMimicDealerId: null,
      },
      gReCaptchaToken: '',
    };
  }

  setSharedState = (name, value, multipleItems) => {
    if (multipleItems) {
      this.setState({ sharedState: { ...this.state.sharedState, ...multipleItems } });
    } else {
      this.setState({ sharedState: { ...this.state.sharedState, [name]: value } });
    }
  };

  refreshPageIfNecessary = () => {
    if (!this.state.sharedState.cookieUpdated) return;

    this.setSharedState('cookieUpdated', false);
    window.location.reload();
  };

  rewriteReferrer = () => {
    const savedReferrer = localStorage.getItem('savedReferrer');

    if (savedReferrer) {
      Object.defineProperty(document, 'referrer', {
        get: function () {
          return savedReferrer;
        },
      });
      localStorage.removeItem('savedReferrer');
    }
  };

  componentDidMount = () => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if (isIE11) {
      document.body.classList.add('is-ie11');
    }

    this.rewriteReferrer();

    //triggered when history.back() or when browser back button was used
    window.onpopstate = (_event) => window.location.reload();
  };

  onContentLoad = () => {
    this.setState({ contentLoading: true });
  };

  onContentLoaded = (pageData = {}) => {
    this.setState({
      contentLoading: false,
      ...pageData,
    });
  };

  onToggleContact = (
    contactType = 0,
    contactModel = '',
    preventCloseModal = false,
    clientType = null
  ) => {
    if (this.state.showContact) {
      this.setState({
        showContact: preventCloseModal ? this.state.showContact : !this.state.showContact,
        contactType,
        contactModel,
        sharedState: {
          ...this.state.sharedState,
          modalType: null,
          clientType,
          carModel: null,
        },
      });
    } else {
      this.setState({
        showContact: !this.state.showContact,
        showMaintenanceModal: false,
        contactType,
        contactModel,
        sharedState: {
          ...this.state.sharedState,
          clientType,
        },
      });
    }
  };

  onToggleConfigurator = () => {
    this.setState({ showCarConfigurator: !this.state.showCarConfigurator });
  };

  renderPageContainer = (entry) => {
    const { dealer, sharedState } = this.state;

    return (
      <Route key={entry.id} path={entry.path} exact={true}>
        <GenericContainer
          dealer={dealer}
          entry={entry}
          onContentLoad={this.onContentLoad}
          onContentLoaded={this.onContentLoaded}
          onOpenContact={this.onToggleContact}
          onOpenConfigurator={this.onToggleConfigurator}
          setSharedState={this.setSharedState}
          sharedState={sharedState}
        />
      </Route>
    );
  };

  gaSendTags = (isAnalytics = false, isMarketing = false) => {
    let ad_storage = 'denied';
    let analytics_storage = 'denied';
    let ad_user_data = 'denied';
    let ad_personalization = 'denied';

    if (isAnalytics === true && isMarketing === false) {
      analytics_storage = 'granted';
    }
    if (isAnalytics === false && isMarketing === true) {
      ad_storage = 'granted';
      ad_user_data = 'granted';
      ad_personalization = 'granted';
    }
    if (isAnalytics === true && isMarketing === true) {
      ad_storage = 'granted';
      analytics_storage = 'granted';
      ad_user_data = 'granted';
      ad_personalization = 'granted';
    }
    if (window.dataLayer) {
      function gtag() {
        window.dataLayer.push(arguments);
      }

      let found_ad_storage = 'denied';
      let found_analytics_storage = 'denied';
      let found_ad_user_data = 'denied';
      let found_ad_personalization = 'denied';

      window.dataLayer.forEach((element) => {
        if (element[0] === 'consent' && element[1] === 'update') {
          found_ad_storage = element[2].ad_storage;
          found_analytics_storage = element[2].analytics_storage;
          found_ad_user_data = element[2].ad_user_data;
          found_ad_personalization = element[2].ad_personalization;
        }
      });

      if (
        found_ad_storage !== ad_storage ||
        found_analytics_storage !== analytics_storage ||
        found_ad_user_data !== ad_user_data ||
        found_ad_personalization !== ad_personalization
      ) {
        gtag('consent', 'update', {
          ad_storage: ad_storage,
          analytics_storage: analytics_storage,
          ad_user_data: ad_user_data,
          ad_personalization: ad_personalization,
        });
      }
    }
  };

  get requestedModalIsBlocked() {
    return false;
  }

  get showMaintenanceModal() {
    const { hwsconfig } = this.state;

    if (
      hwsconfig &&
      hwsconfig.enableUsedCarEvaluation === false &&
      (window.location.href.includes('/gebrauchtwagenbewertung') ||
        window.location.href.includes('/gebrauchtwagenbewertung-2nd-level'))
    ) {
      return true;
    }

    return false;
  }

  get redirects() {
    if (this.showMaintenanceModal) {
      this.setState({ showContact: true, showMaintenanceModal: true });
      return <Redirect to="" />;
    }

    const href = window.location.href;
    const isSalesPartner = this.state.dealer.settings.isSalesPartner;
    const { customTopCars } = this.state.dealer.settings;

    if (this.requestedModalIsBlocked) {
      if (this.state.showContact) {
        this.onToggleContact();
      }
      return <Redirect to="" />;
    }

    if (href.includes('ueber-uns/autohaus/unsere-standorte/')) {
      return <Redirect to="/?showlocations=true" />;
    }

    if (customTopCars.isActive && href.includes('beratung-und-kauf/fahrzeugboerse')) {
      return <Redirect to="/?modaltype=TOPCARS&showmodal=true" />;
    }

    if (!customTopCars.isActive && href.includes('modaltype=TOPCARS')) {
      return <Redirect to="" />;
    }

    if (href.includes('/beratung-und-kauf/mazda-modelle/konfigurator')) {
      return <Redirect to="?modaltype=CAR_CONFIGURATOR&showmodal=true" />;
    }

    if (href.includes('verkaufs-terminanfrage')) {
      return isSalesPartner ? (
        <Redirect to="/?modaltype=REQUEST_FOR_CONSULTATION&showmodal=true" />
      ) : (
        <Redirect to="" />
      );
    }
  }

  render() {
    const {
      dealer,
      error,
      errorStatus,
      showContact,
      contactType,
      contactModel,
      showCarConfigurator,
      showMaintenanceModal,
    } = this.state;
    const hascookie = hasCookie('privacySettings')
      ? JSON.parse(getCookie('privacySettings'))
      : false;

    if (error) {
      return (
        <BrowserRouter>
          <Route>
            <Analytics>
              <Layout
                dealer={new DealerModel()}
                footer={false}
                error={error}
                forceMobileMenu={this.state.sharedState.forceMobileMenu}
                setSharedState={this.setSharedState}
              >
                <ErrorPage error={error} status={errorStatus} />
              </Layout>
            </Analytics>
          </Route>
        </BrowserRouter>
      );
    }

    return (
      <ErrorBoundary>
        <GlobalContext.Provider value={this.state}>
          <BrowserRouter>
            {this.redirects}
            <Switch>
              <Route path="/admin">
                <Analytics>
                  <Suspense fallback={<div></div>}>
                    <DealerBackend
                      dealer={dealer}
                      forceMobileMenu={this.state.sharedState.forceMobileMenu}
                    />
                  </Suspense>
                </Analytics>
              </Route>
              <Route path="/components">
                <Suspense fallback={<div></div>}>
                  <Styleguide dealer={dealer} />
                </Suspense>
              </Route>
              <Route path="/">
                <Analytics>
                  <Suspense fallback={<div></div>}>
                    <CookieBanner />
                    <FixedButton isWhatsappButton={true} settings={dealer.settings} />
                    <ScrollToTop settings={dealer.settings} />
                  </Suspense>
                  <Layout
                    dealer={dealer}
                    menu={dealer.menu}
                    footerVisible={!this.state.contentLoading}
                    onOpenContact={this.onToggleContact}
                    refreshPageIfNecessary={this.refreshPageIfNecessary}
                    forceMobileMenu={this.state.sharedState.forceMobileMenu}
                    setSharedState={this.setSharedState}
                  >
                    <Switch>
                      {/* CUSTOM Switch */}
                      <Route path="/beratung-und-kauf/fahrzeugboerse/:type(neuwagen|gebrauchtwagen|haendlerzulassung)/:id?">
                        <Cars dealer={dealer} />
                      </Route>
                      <Route path="/gebrauchtwagenbewertung/zusammenfassung/:id?">
                        <GenericContainer
                          dealer={dealer}
                          entry={dealer.routes.find(
                            (entry) => entry.path === '/gebrauchtwagenbewertung/zusammenfassung/'
                          )}
                          onContentLoad={this.onContentLoad}
                          onContentLoaded={this.onContentLoaded}
                          onOpenContact={this.onToggleContact}
                          onOpenConfigurator={this.onToggleConfigurator}
                          setSharedState={this.setSharedState}
                          sharedState={this.sharedState}
                        />
                      </Route>
                      {/* Contact-Modal Switch: MAZDAHWS-1357 */}
                      <Route
                        path="/:modal(werkstatttermin|probefahrt|angebotsanfrage)"
                        render={({ location }) => (
                          <GenericContainer
                            dealer={dealer}
                            entry={dealer.routes.find((entry) => entry.path === '/')}
                            onContentLoad={this.onContentLoad}
                            onContentLoaded={() => {
                              const newState = { contentLoading: false };
                              if (location) {
                                let path = location.pathname;
                                // ensure path ends with `/`
                                path += path.substr(-1) === '/' ? '' : '/';
                                switch (path) {
                                  case '/angebotsanfrage/':
                                  case '/werkstatttermin/':
                                  case '/probefahrt/':
                                    newState.contactType = CONTACT_TYPES.indexOf(
                                      path.substr(1, path.length - 2).toUpperCase()
                                    );
                                    newState.showContact = true;
                                    break;
                                  default:
                                    break;
                                }
                              }
                              this.setState(newState);
                            }}
                            onOpenContact={this.onToggleContact}
                            onOpenConfigurator={this.onToggleConfigurator}
                            sharedState={this.state.sharedState}
                            setSharedState={this.setSharedState}
                          />
                        )}
                      />
                      <Route
                        path="/:type(werkstatttermin|probefahrt)"
                        render={({ location }) => (
                          <GenericContainer
                            dealer={dealer}
                            entry={dealer.routes.find((entry) => entry.path === '/')}
                            onContentLoad={this.onContentLoad}
                            onContentLoaded={() => {
                              const newState = { contentLoading: false };
                              if (location) {
                                const path = location.pathname;
                                switch (path) {
                                  case '/werkstatttermin/':
                                  case '/probefahrt/':
                                    newState.contactType = CONTACT_TYPES.indexOf(
                                      path.substr(1, path.length - 2).toUpperCase()
                                    );
                                    newState.showContact = true;
                                    break;
                                  default:
                                    break;
                                }
                              }
                              this.setState(newState);
                            }}
                            onOpenContact={this.onToggleContact}
                            onOpenConfigurator={this.onToggleConfigurator}
                            sharedState={this.state.sharedState}
                            setSharedState={this.setSharedState}
                          />
                        )}
                      />
                      {/* GENERIC Switch */}
                      {dealer.routes.map((entry) => this.renderPageContainer(entry))}
                      <Route>
                        <ErrorPage />
                      </Route>
                    </Switch>

                    <Suspense fallback={<div></div>}>
                      <ContactModal
                        sharedState={this.state.sharedState}
                        setSharedState={this.setSharedState}
                        dealer={dealer}
                        type={contactType}
                        model={contactModel}
                        isOpen={showContact}
                        onClose={this.onToggleContact}
                        requestedModalIsBlocked={this.requestedModalIsBlocked}
                        showMaintenanceModal={showMaintenanceModal}
                      />
                      <ConfiguratorModal
                        sharedState={this.state.sharedState}
                        setSharedState={this.setSharedState}
                        dealer={dealer}
                        isOpen={showCarConfigurator}
                        onClose={this.onToggleConfigurator}
                      />
                    </Suspense>
                    <Alliance dealer={dealer} />
                    {hascookie
                      ? this.gaSendTags(
                          hascookie.cookieTypes.performance,
                          hascookie.cookieTypes.marketing
                        )
                      : null}
                  </Layout>
                </Analytics>
              </Route>
            </Switch>
          </BrowserRouter>
        </GlobalContext.Provider>
      </ErrorBoundary>
    );
  }
}
