// @flow
import React from 'react';
import { FocusedImage as FImage } from 'image-focus';
import AssetModel from 'Stores/AssetModel';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import { useDebounce } from 'Utils/useDebounce';

const cx = classNames.bind(styles);
// declare var IMAGE_FALLBACK_URL: string;
declare var PUBLIC_BASE_URL: string;

//
// @see https://image-focus.stackblitz.io/
//

type Props = {
  image: AssetModel,
  imageMobile: AssetModel,
  useThumb?: string,
  fullWidth?: boolean,
};

export default class FocusedImage extends React.Component<Props> {
  imageElm: ?HTMLImageElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      useMobileImage: this.props.imageMobile && window.matchMedia('(max-width: 1024px)').matches,
    };
  }

  componentDidMount = () => {
    this.setImageWithFocus();
  };

  componentDidUpdate = () => {
    this.setImageWithFocus();
  };

  updateUseMobileImage = () => {
    const useMobileImage = window.matchMedia('(max-width: 1024px)').matches;

    if (useMobileImage !== this.state.useMobileImage) {
      this.setState({ useMobileImage });
    }
  };

  setImageWithFocus = () => {
    let focus = {
      x: 0,
      y: 0,
    };

    if (this.props.image) {
      focus = this.props.image.focus;
    }

    new FImage(this.imageElm, { focus });

    if (this.props.imageMobile) {
      window.addEventListener('resize', useDebounce({ func: this.updateUseMobileImage }));
    }
  };

  setRef = (elm: ?HTMLImageElement) => {
    this.imageElm = elm;
  };

  render() {
    const { image, imageMobile, useThumb } = this.props;
    let src;
    let alt;
    let thumbnails;
    const fallbackSrc = `${PUBLIC_BASE_URL}/img/fallback-image.jpg`;

    if (image) {
      src = image.src;
      alt = image.alt;
      thumbnails = image.thumbnails;
    }

    if (imageMobile && this.state.useMobileImage) {
      src = imageMobile.src;
      alt = imageMobile.alt;
      thumbnails = imageMobile.thumbnails;
    }

    if (useThumb && thumbnails) {
      src = thumbnails[AssetModel.SIZE[useThumb]] || src;
    }

    const rootClass = cx('container', {
      fullWidth: this.props.fullWidth,
    });

    return (
      <div className={rootClass}>
        <img
          ref={this.setRef}
          src={src || fallbackSrc}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = fallbackSrc;
          }}
          alt={alt}
        />

        {image && image.dim > 0 && (
          <div
            className={styles.dim}
            style={{ backgroundColor: `rgba(25, 25, 25,${image.dim})` }}
          />
        )}
      </div>
    );
  }
}
