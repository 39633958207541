// mutate the given object with the given mutationcallback if the condition is true
export const conditionalMutation = (mutationCallback, condition, obj, ...args) =>
  condition ? mutationCallback(obj, ...args) : obj;

// remove keys from an object (axios/JSON.stringify denies undefined)
export const withoutKeys = (obj, keys) => {
  keys.forEach((key) => {
    obj[key] = undefined;
  });

  return obj;
};

// remove the id from an object
export const withoutId = (obj) => withoutKeys(obj, ['id']);
