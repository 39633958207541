// categories
const categories = {
  Nav: 'nav',
  Leads: 'leads',
  CTA: 'CTA',
  WA: 'wa-widget',
};

// events
const events = {
  ga: 'gaEvent',
  formSubmit: 'formSubmit',
};

// error types
const errorTypes = {
  validation: 'validation',
  timeout: 'timeout',
  server: 'server',
};

// tracking keys as data-attributes
const trackingKeys = {
  category: 'data-track-category',
  action: 'data-track-action',
  label: 'data-track-label',
  mazda_model: 'data-track-model',
  error: 'data-track-error', // nothing that'll actually go into the DOM since it's dynamic
};

// translations for the leadnav tracking
const leadNavItems = {
  Angebotsanfrage: 'OrderPreOrder',
  Beratungstermin: 'RequestAConsultation',
  Gebrauchtwagenbewertung: 'TradeInRequest',
  Konfigurator: 'Configurator',
  Probefahrt: 'TestDriveRequest',
  Werkstatttermin: 'ServiceRequest',
};

// just helper functions
const click = (action) => `${action} click`;

const lower = (str) => str.toLowerCase();

// cached module function for mocked elements
function getAttribute(name) {
  return this[name];
}

// checks if a given element is treated special
export const isSpecialTracked = (element) => element.hasAttribute(trackingKeys.category);

// mocks an element, used in cases where we manipulate by js and have no element to use
// (example: forms, we track AFTER successful submitting => no reference to the element itself)
export const mockTrackedElement = (data) => ({ getAttribute, ...data });

// transforms a given element with trackingkeys to a tracking object, details rely on the category
export const getTrackingDataFromElement = (element) => {
  const category = element.getAttribute(trackingKeys.category);

  const common = {
    category,
    action: element.getAttribute(trackingKeys.action),
    label: lower(element.getAttribute(trackingKeys.label)),
  };

  switch (category) {
    case categories.Nav:
    case categories.CTA:
    case categories.WA:
      return {
        ...common,
        event: events.ga,
      };
    case categories.Leads:
      return {
        ...common,
        event: events.formSubmit,
        mazda_model: element.getAttribute(trackingKeys.mazda_model),
        error: element.getAttribute(trackingKeys.error) || undefined, // undefined is removed by axios, null isn't
      };
    default:
      // impossible case unless no check was done if it's a special element!
      return common;
  }
};

// the allmighty tracking object to spread on elements
export const genericTracking = (category, action, label) => ({
  [trackingKeys.category]: category,
  [trackingKeys.action]: click(action),
  [trackingKeys.label]: label,
});

// also (but less than above) generic tracking objects which specify the above a bit
export const navTracking = (action, label) => genericTracking(categories.Nav, action, label);
export const ctaTracking = (action, label) => genericTracking(categories.CTA, action, label);
export const waTracking = (action, label) => genericTracking(categories.WA, action, label);

// specific tracking objects til the end
export const leadTracking = (callback) => navTracking('lead bar', callback(leadNavItems)); // callback => translating the german
export const firstLevelTracking = (label) => navTracking('first level nav', label);
export const secondLevelTracking = (label) => navTracking('second level nav', label);
export const footerTracking = (label) => navTracking('footer', label);

export const telephoneTracking = (label) => ctaTracking('telephone', label);
export const emailTracking = (label) => ctaTracking('email', label);
export const whatsappTracking = (label) => ctaTracking('whatsapp', label);
export const addressTracking = (label) => ctaTracking('address', label);
export const flyoutTeaserTracking = (label) => ctaTracking('menu button', label);
export const cardTracking = (text, title) => ctaTracking('card', `${text} | ${title}`);

export const whatsappTrackingWa = (label) => waTracking('whatsapp', label);

export const formSubmitTracking = (origin, carmodel, person) => ({
  [trackingKeys.category]: categories.Leads,
  [trackingKeys.action]: 'form submit complete',
  [trackingKeys.label]: `conversion:${origin}:${carmodel || ''}${person ? `:${person}` : ''}`,
  [trackingKeys.mazda_model]: carmodel || '',
});

export const formSubmitErrorTracking = (origin, carmodel, person, { type, errors } = {}) => ({
  [trackingKeys.category]: categories.Leads,
  [trackingKeys.action]: 'form submit error',
  [trackingKeys.label]: `${origin}:submit:error-${
    (type && type(errorTypes)) || 'unknown'
  }:${carmodel || ''}${person ? `:${person}` : ''}`,
  [trackingKeys.mazda_model]: carmodel || '',
  [trackingKeys.error]: `${JSON.stringify(errors)}`,
});

export const consultationFormSubmitTracking = (label) => ({
  [trackingKeys.category]: categories.Leads,
  [trackingKeys.action]: 'form submit complete',
  [trackingKeys.label]: label,
});

export const consultationFormSubmitErrorTracking = ({ type, errors }) => ({
  [trackingKeys.category]: categories.Leads,
  [trackingKeys.action]: 'form submit error',
  [trackingKeys.label]: `requestaconsultation:submit:${type}`,
});
